import { createSlice, current } from "@reduxjs/toolkit";
import service from "../services/service";
import { getStorage } from "../../../utilities/browserStorage";
import { initialState, procedure as newProcedureRowItem, procedure } from "./initialState";
import { RESPONSIBILITY_TYPES } from "../../../utilities/dictionaryConstants";
import i18n from "../../../utilities/i18n";
import { decimalConverter } from "../../../utilities/commonUtilities";
import format from "date-fns/format";


import {
    generateAdditionalclaimSaveData, validateDiagnosis,
    generateBillingInfoUpdateData, generateBillingTabSaveData,
    generateClaimCommonUpdateData, generateServiceLineSaveData,
    generateServiceLineUpdateData,
    getServiceDateFrom, getServiceDateTo, validateModifier,
    generateAdditionaleUpdateData,
    setWarningFromServer,
    checkForGapsBetweenIcd,
    validateIsValidDxPointer,
    updateDXpointer,
} from "./claimDataGenerator";

import {
    ActivateInactiveClaim,
    addActiveSubTab,
    addNewProcedureFromChargePanel,
    addRemoveFromSelectedTab,
    createAndUpdateClaim,
    fetchIdentifiers,
    getChargePanelsList,
    GetCPTCodeListWithDate,
    getEditDetails,
    getlast10TableData,
    getPatientDetails,
    getPatientHistory,
    getSearchClaimsTableData,
    GetServiceLocationPOSList,
    getUserOpenedClaimModuleTabs,
} from "./asyncThunkAPI";
import { TAB1 } from "../../../utilities/staticConfigs";


const claimManagementSlice1 = createSlice({
    name: 'claimManagement',
    initialState,
    reducers: {
        resetToInitial: () => initialState,
        /**
         * setting opened current tab here
         * @param {*} state 
         * @param {*} action 
         */
        setOpenTab: (state, action) => {
            state.tabsOpen = action.payload;
        },

        /**
         * @description Handles Last 10 tabs sorting logic
         * @param {*} state 
         * @param {string} action.payload receives the name of the field to be sorted 
         */
        setLast10Sort: (state, action) => {
            const sortField = action.payload;

            // Set sort field and order type
            state.last10Table.sortField = sortField;
            state.last10Table.orderType = state.last10Table.orderType === "" ? "-" : "";

            // Reset all ordering flags to false
            const resetOrderingFlags = () => {
                state.last10Table.initialOrderingPatientName = true;
                state.last10Table.initialOrderingDos = true;
                state.last10Table.initialOrderingBillDate = true;
                state.last10Table.initialOrderingClaimId = true;
            };

            // Set the appropriate ordering flag based on sort field
            const setOrderingFlag = (flagName) => {
                resetOrderingFlags();
                state.last10Table[flagName] = false;
            };

            switch (sortField) {
                case "patient__full_name":
                    setOrderingFlag('initialOrderingPatientName');
                    break;
                case "custom_claim_id":
                    setOrderingFlag('initialOrderingClaimId');
                    break;
                case "service_from_date":
                    setOrderingFlag('initialOrderingDos');
                    break;
                case "last_billed_date":
                    setOrderingFlag('initialOrderingBillDate');
                    break;
                default:
                    resetOrderingFlags();
                    break;
            }
        },

        /**
         * @description Handles the value change in the SearchClaims Tab Filter inputs
         * @param {*} state 
         * @param {*} action 
         */
        setSearchClaimFilterData: (state, action) => {
            const field = action.payload.field;
            const value = action.payload.value;

            state.searchClaimsTable[field] = value
        },

        /**
         * @description Handles Search Claims Table sorting logic
         * @param {*} state 
         * @param {string} action.payload receives the name of the field to be sorted 
         */
        setSearchClaimsSort: (state, action) => {
            const sortField = action.payload;

            // Set sort field and order type
            state.searchClaimsTable.sortField = sortField;
            state.searchClaimsTable.orderType = state.searchClaimsTable.orderType === "" ? "-" : "";

            // Reset all ordering flags to false
            const resetOrderingFlags = () => {
                state.searchClaimsTable.initialOrderingPatientName = true;
                state.searchClaimsTable.initialOrderingDos = true;
                state.searchClaimsTable.initialOrderingBillDate = true;
                state.searchClaimsTable.initialOrderingClaimId = true;
            };

            // Set the appropriate ordering flag based on sort field
            const setOrderingFlag = (flagName) => {
                resetOrderingFlags();
                state.searchClaimsTable[flagName] = false;
            };

            switch (sortField) {
                case "patient__full_name":
                    setOrderingFlag('initialOrderingPatientName');
                    break;
                case "custom_claim_id":
                    setOrderingFlag('initialOrderingClaimId');
                    break;
                case "service_from_date":
                    setOrderingFlag('initialOrderingDos');
                    break;
                case "last_billed_date":
                    setOrderingFlag('initialOrderingBillDate');
                    break;
                default:
                    resetOrderingFlags();
                    break;
            }
        },

        resetSearchClaimData: (state) => {
            state.searchClaimsTable = initialState.searchClaimsTable;
            state.apiError = "";
            state.dataLoading = false
        },

        /**
         * reducer function to save patient initial data to state on patient search select
         * function will dispatch when a particular patient search with @name @id @dob and click on the search result
         * this function only used to create a new claim
         * @param {*} state 
         * @param {*} actions 
         */
        onPatientDropDownSelectAction: (state, actions) => {
            let { commonData, patientSelected, patientAdvSearchData, selectPatient } = actions.payload;
            let data = {
                ...commonData,
                claim_pk: 'add_new'
            }

            state.professionalClaim.push(data);
            state.patientSelectedData = patientSelected;
            state.patientAdvanceSearchData = patientAdvSearchData;
            state.selectedPatient = selectPatient;
            state.claimPK = ""
            state.diagnosis_pk = "";
            state.billing_info_pk = '',
                state.addition_info_pk = ""
            state.professionalClaimDetails = data;
            state.infoLoaded = true;
            /**pushing all pks */
            const pks = {
                ...commonData.pks,
                claim_pk: 'add_new',

            }
            state.pks.push(pks);
        },

        /**
         * function is used to update value of parent component
         * action contains field to be updated and value and claimPK
         * @param {*} state 
         * @param {*} actions 
         */
        updateParentComponentData: (state, actions) => {
            let { field, value, claimPK, claimStatus,claim_sub_status } = actions.payload;
            let { professionalClaimDetails, professionalClaim, serviceLineDetails, serviceLineData, claimSubStatus } = current(state);
            let claim_pk = claimPK ? claimPK : "add_new";
            let updateValue = {
                ...professionalClaimDetails,
                [field]: value
            }

            //  Whenever there's a change in the authorization_referral change its corresponding identifier into empty string
            if (field == "authorization_referral") {
                updateValue = {
                    ...updateValue,
                    identifier: ""
                }
            }

            /**checking field is inactive and if value is true making active to false because in_active is true */
            if (field == "claim_inactive" && value == true) {
                updateValue = {
                    ...updateValue,
                    active: false
                }
            }
            /**checking field is inactive and if value is false making active to true because in_active is false
             * also making activeModal to true to show active modal window
             */
            if (field == "claim_inactive" && value == false) {
                updateValue = {
                    ...updateValue,
                    active: true
                }

                state.activeClaimModal = true;
            }

            if (field === "claim_status") {
                updateValue = {
                    ...updateValue,
                    "claim_sub_status": ""
                }
            }

            if (field === "claim_sub_status") {
                updateValue = {

                    ...updateValue,
                    "claim_sub_status": value
                }
            }
            /**
             * updating profession claim details immediately
             */
            state.professionalClaimDetails = updateValue;

            /**
             * when updating claim_status in parent component it is will reflect in procedure 
             * code below will do that
             */
            let claimStatusError = [];
            if (field === "claim_status") {
                let claimStatusPriority = claimStatus?.filter((element) => element.id === value);

                if (claimStatusPriority.length > 0) {
                    serviceLineDetails?.procedures?.forEach((procedure, index) => {
                        let checked = claimStatusPriority[0]?.priorities?.includes(procedure.responsibility_type);
                        if (checked) {
                            let updatedProcedure = {
                                ...procedure,
                                claim_status: value,
                                claim_sub_status: "",
                            };

                            // Update the procedure at the current index
                            state.serviceLineDetails.procedures[index] = updatedProcedure;

                            if (claimSubStatus.length > 0) {
                                let filterData = claimSubStatus?.filter(item => item.parent_claim_status_ids?.includes(value));
                                state.serviceLineDetails.claim_sub_status[index] = filterData;
                            }
                        }
                        else {
                            let responsibility = RESPONSIBILITY_TYPES.filter((item) => item.id == procedure.responsibility_type);
                            let errordata = `Procedure ${index + 1} with responsibility ${responsibility[0].name}`
                            claimStatusError.push(errordata)
                            let updatedProcedure = {
                                ...procedure,
                                claim_status: "",
                                claim_sub_status: "",
                            };
                            // Update the procedure at the current index
                            state.serviceLineDetails.procedures[index] = updatedProcedure;
                            state.serviceLineDetails.claim_sub_status[index] = [];
                        }
                    });
                }
                else {
                    serviceLineDetails?.procedures?.forEach((procedure, index) => {
                        let updatedProcedure = {
                            ...procedure,
                            claim_status: "",
                            claim_sub_status: "",
                        };
                        // Update the procedure at the current index
                        state.serviceLineDetails.procedures[index] = updatedProcedure;
                        state.serviceLineDetails.claim_sub_status[index] = [];
                    });
                }
                let message = "";
                if (claimStatusError.length > 0) {
                    message += claimStatusError.join(' and ') + ". The selected status is not under these responsibilities.";
                    state.notifyMessage = message;
                    state.showNotification = true;
                    state.statusTag = "error";
                }
                /**
               * changing value of service line info array value
               */

                let index = serviceLineData.findIndex((item) => item.claim_pk === claim_pk);
                if (index >= 0) {
                    state.serviceLineData[index] = state.serviceLineDetails;
                }
            }
            if (field == "claim_sub_status" && value) {
                serviceLineDetails?.procedures?.forEach((procedure, index) => {
                    if (claim_sub_status.length > 0) {
                        claim_sub_status.forEach((status_data) => {
                            if (status_data.id === value) {
                                let updatedProcedure = {
                                    ...procedure,
                                    claim_sub_status: value,
                                };
                                state.serviceLineDetails.procedures[index] = updatedProcedure;
                            }
                        })
                    }
                })

                let index = serviceLineData.findIndex((item) => item.claim_pk === claim_pk);
                if (index >= 0) {
                    state.serviceLineData[index] = state.serviceLineDetails;
                }
            }


            /**
             * changing value of professional claim array value
             */

            let index = professionalClaim.findIndex((item) => item.claim_pk === claim_pk);
            if (index >= 0) {
                state.professionalClaim[index] = updateValue;
            }
        },
        /**
         * function is used to update value of parent component
         * action contains field to be updated and value and claimPK
         * @param {*} state 
         * @param {*} actions 
         */
        updateBillingInfoData: (state, actions) => {
            let { field, value, claimPK } = actions.payload;
            let { billingInfoDetails, billingInfo, professionalClaimDetails, professionalClaim } = current(state);
            let claim_pk = claimPK ? claimPK : "add_new";
            let updateValue;
            if (field === "practice_location" || field === "facility_location") {
                let service_location_id = value.split("||")[0];
                let service_location_type = value.split("||")[1];
                updateValue = {
                    ...billingInfoDetails,
                    [field]: parseInt(service_location_id),
                    service_location_value: value,
                    service_location: { id: service_location_id, location_type: service_location_type },
                    location_type: service_location_type
                }
            }
            else {
                updateValue = {
                    ...billingInfoDetails,
                    [field]: value
                }
            }
            /**
             * updating billing info details immediately
             */
            state.billingInfoDetails = updateValue;


            /**
             * changing value of billing info array value
             */

            let index = billingInfo.findIndex((item) => item.claim_pk === claim_pk);
            if (index >= 0) {
                state.billingInfo[index] = updateValue;
            }

            /**
             * resetting authorization data on billing provider change
             */

            if (field == "billing_provider") {
                let updateValue = {
                    ...professionalClaimDetails,
                    "identifier": "",
                    "authorization_referral": ""
                }

                state.professionalClaimDetails = updateValue;
                /**
                      * changing value of professional claim array value
                */

                let index1 = professionalClaim.findIndex((item) => item.claim_pk === claim_pk);
                if (index1 >= 0) {
                    state.professionalClaim[index1] = updateValue;
                }
            }

        },

        /**
         * function used to update payer details
         * @param {*} state 
         * @param {*} actions 
         */
        updatePayerInfoDetails: (state, actions) => {
            let { fieldType, priority, value, claimPK } = actions.payload;
            let { billingInfo, billingInfoDetails
            } = current(state);
            let claim_pk = claimPK ? claimPK : "add_new";
            let priorityInDigit = 0;
            if (priority === 'primary')
                priorityInDigit = 1;
            else if (priority === 'secondary')
                priorityInDigit = 2;
            else if (priority === 'tertiary')
                priorityInDigit = 3;
            let arryObj = {};
            let updateValue = {
                ...billingInfoDetails
            }
            let arrInsure = [...(Array.isArray(state.billingInfoDetails.payerInsuranceList) ? state.billingInfoDetails.payerInsuranceList : [])];
            /**
             * getting data using priority
             */

            let payerObj = billingInfoDetails?.payerInsuranceList?.filter((pIObj) => pIObj.priority === priorityInDigit);
            if (payerObj && payerObj.length > 0) {
                arryObj = { ...payerObj[0] };
                delete arryObj.id;
            }
            /**
             * storing primary insurance data here
             */
            if (priority === 'primary') {
                arryObj.priority = 1;
                if (fieldType === 'insurance') {
                    if (value && value != '0') {
                        let primaryInsureObj = billingInfoDetails?.primaryInsuranceDataList?.filter((pIObj) => pIObj.id === value);
                        if (primaryInsureObj) {
                            updateValue = {
                                ...updateValue,
                                insurancePrimary: value,
                                primarypolicyId: primaryInsureObj[0].policy_id,
                            }
                            arryObj.policy_id = primaryInsureObj[0].policy_id;
                            arryObj.practice_insurance_id = primaryInsureObj[0].id;
                        }
                        else {
                            updateValue = {
                                ...updateValue,
                                insurancePrimary: "",
                                primarypolicyId: "",
                            }
                            delete arryObj['priority'];
                            delete arryObj['practice_insurance_id'];
                            delete arryObj['policy_id'];
                        }
                    }
                    else {
                        updateValue = {
                            ...updateValue,
                            insurancePrimary: "",
                            primarypolicyId: "",
                        }
                        delete arryObj['priority'];
                        delete arryObj['practice_insurance_id'];
                        delete arryObj['policy_id'];
                    }
                }
                if (fieldType === 'policyId') {
                    updateValue = {
                        ...updateValue,
                        primarypolicyId: value,
                    }
                    arryObj.policy_id = value;
                }
            }
            /**
            * storing secondary insurance data here
            */
            if (priority === 'secondary') {
                arryObj.priority = 2;
                if (fieldType === 'insurance') {
                    if (value && value != '0') {
                        let secondaryInsureObj = billingInfoDetails?.secondaryInsuranceDataList?.filter((pIObj) => pIObj.id === value);
                        if (secondaryInsureObj) {
                            updateValue = {
                                ...updateValue,
                                insurancesecondary: value,
                                secondarypolicyId: secondaryInsureObj[0].policy_id,
                            }
                            arryObj.policy_id = secondaryInsureObj[0].policy_id;
                            arryObj.practice_insurance_id = secondaryInsureObj[0].id;
                        }
                        else {
                            updateValue = {
                                ...updateValue,
                                insurancesecondary: "",
                                secondarypolicyId: "",
                            }
                            delete arryObj['priority'];
                            delete arryObj['practice_insurance_id'];
                            delete arryObj['policy_id'];
                        }
                    }
                    else {
                        updateValue = {
                            ...updateValue,
                            insurancesecondary: "",
                            secondarypolicyId: "",
                        }
                        delete arryObj['priority'];
                        delete arryObj['practice_insurance_id'];
                        delete arryObj['policy_id'];
                    }
                }
                if (fieldType === 'policyId') {
                    updateValue = {
                        ...updateValue,
                        secondarypolicyId: value,
                    }
                    arryObj.policy_id = value;
                }
            }
            /**
            * storing tertiary insurance data here
           */
            if (priority === 'tertiary') {
                arryObj.priority = 3;
                if (fieldType === 'insurance') {
                    if (value && value != '0') {
                        let tertiaryInsureObj = billingInfoDetails?.tertiaryInsuranceDataList.filter((pIObj) => pIObj.id === value);
                        if (tertiaryInsureObj) {
                            updateValue = {
                                ...updateValue,
                                insuranceTertiary: value,
                                tertiarypolicyId: tertiaryInsureObj[0].policy_id,
                            }
                            arryObj.policy_id = tertiaryInsureObj[0].policy_id;
                            arryObj.practice_insurance_id = tertiaryInsureObj[0].id;
                        } else {
                            updateValue = {
                                ...updateValue,
                                insuranceTertiary: "",
                                tertiarypolicyId: '',
                            }
                            delete arryObj['priority'];
                            delete arryObj['practice_insurance_id'];
                            delete arryObj['policy_id'];
                        }
                    } else {
                        updateValue = {
                            ...updateValue,
                            insuranceTertiary: "",
                            tertiarypolicyId: '',
                        }
                        delete arryObj['priority'];
                        delete arryObj['practice_insurance_id'];
                        delete arryObj['policy_id'];
                    }
                }
                if (fieldType === 'policyId') {
                    updateValue = {
                        ...updateValue,
                        tertiarypolicyId: value,
                    }
                    arryObj.policy_id = value;
                }
            }

            /**
             * making new payer info details as per the changes
             */
            if (Object.keys(arryObj).length == 0) {
                const index = arrInsure.findIndex((item) => item.priority == priorityInDigit);
                if (index >= 0) {
                    arrInsure.splice(index, 1);
                }
            }
            else {
                const index = arrInsure.findIndex((item) => item.priority == priorityInDigit);
                if (index >= 0) {
                    arrInsure[index] = arryObj;
                }
                else {
                    arrInsure.push(arryObj);
                }
            }

            // Filter to remove incomplete entries
            arrInsure = arrInsure.filter(
                (item) => item.practice_insurance_id && item.policy_id
            );

            if (Object.keys(arrInsure).length > 0) {
                updateValue = {
                    ...updateValue,
                    ["payerInsuranceList"]: arrInsure,
                    ['payer_info']: arrInsure,
                }

                /**
            * updating billing info details immediately
            */
                state.billingInfoDetails = updateValue;


                /**
                 * changing value of billing info array value
                 */

                let index = billingInfo.findIndex((item) => item.claim_pk === claim_pk);
                if (index >= 0) {
                    state.billingInfo[index] = updateValue;
                }
            }
            else {
                updateValue = {
                    ...updateValue,
                    ["payerInsuranceList"]: arrInsure,
                    ['payer_info']: []
                }

                /**
            * updating billing info details immediately
            */
                state.billingInfoDetails = updateValue;


                /**
                 * changing value of billing info array value
                 */

                let index = billingInfo.findIndex((item) => item.claim_pk === claim_pk);
                if (index >= 0) {
                    state.billingInfo[index] = updateValue;
                }
            }
        },
        /**
         * function is used to update value of parent component
         * action contains field to be updated and value and claimPK
         * @param {*} state 
         * @param {*} actions 
         */
        updateAdditionalInfoData: (state, actions) => {
            let { field, value, claimPK } = actions.payload;
            let { additionalClaimData, additionalClaimDetails } = current(state);
            let claim_pk = claimPK ? claimPK : "add_new";
            let updateValue = {
                ...additionalClaimDetails,
                [field]: value
            }

            if (field == "accident") {
                updateValue = {
                    ...updateValue,
                    "accident_date": "",
                    "state": "",
                    "other_accident": ""
                }
            }
            /**
             * updating billing info details immediately
             */
            state.additionalClaimDetails = updateValue;


            /**
             * changing value of billing info array value
             */

            let index = additionalClaimData.findIndex((item) => item.claim_pk === claim_pk);
            if (index >= 0) {
                state.additionalClaimData[index] = updateValue;
            }
        },

        /**
         * function to update cpt of procedure
         * @param {*} state 
         * @param {*} actions 
         */
        updateProcedureCPT: (state, actions) => {
            let { serviceLineDetails, serviceLocationPosList, serviceLineData } = current(state);
            let { valueList, field, rowId, claimPK } = actions.payload;
            let claim_pk = claimPK ? claimPK : "add_new";
            if (valueList.length > 0) {
                let newData = { ...serviceLineDetails?.procedures[rowId] };
                newData = {
                    ...newData,
                    [field]: valueList[0].id,
                    [field + 'List']: valueList,
                    ['proced_description']: valueList[0].description
                }
                /**
                 * checking and updating procedure value list have pos with value
                 */
                if (valueList[0].pos) {
                    let posList = [{
                        id: valueList[0]?.pos?.id,
                        label: valueList[0]?.pos?.label,
                        name: valueList[0]?.pos?.name,
                    }]
                    newData = { ...newData, pos_id: valueList[0].pos.id, pos_id_List: posList };
                }
                else {
                    if (serviceLocationPosList.length > 0) {
                        let pos_List = [{
                            id: serviceLocationPosList[0]?.id,
                            label: serviceLocationPosList[0]?.label,
                            name: serviceLocationPosList[0]?.name,
                        }]

                        newData = { ...newData, pos_id: serviceLocationPosList[0].id, pos_id_List: pos_List };
                    }
                }

                /**
                 * checking for units
                 */
                if (valueList[0].units) {
                    newData = { ...newData, fee_units: valueList[0].units }
                }

                /**
                 * checking for tos
                 */
                if (valueList[0].tos) {
                    let tosList = [{
                        id: valueList[0]?.tos?.id,
                        label: valueList[0]?.tos?.name,
                        name: valueList[0]?.tos?.name,
                    }]
                    newData = { ...newData, tos_id: valueList[0].tos.id, tos_id_List: tosList };
                }
                if (valueList[0].fee) {
                    newData = { ...newData, fee: decimalConverter(valueList[0].fee) }
                }

                if (valueList[0].units && valueList[0].fee) {
                    newData = { ...newData, charges: decimalConverter(valueList[0].fee) * parseInt(valueList[0].units) }
                }
                /**
                 * checking for modifier list 
                 */
                if (valueList[0].m1_id && valueList[0].m1_value.length > 0) {
                    newData = { ...newData, "m1_id": valueList[0].m1_id, "m1List": valueList[0].m1_value };
                }
                else {
                    newData = { ...newData, "m1_id": "", "m1List": [] };
                }
                if (valueList[0].m2_id && valueList[0].m2_value.length > 0) {
                    newData = { ...newData, "m2_id": valueList[0].m2_id, "m2List": valueList[0].m2_value };
                }
                else {
                    newData = { ...newData, "m2_id": "", "m2List": [] };
                }
                if (valueList[0].m3_id && valueList[0].m3_value.length > 0) {
                    newData = { ...newData, "m3_id": valueList[0].m3_id, "m3List": valueList[0].m3_value };
                }
                else {
                    newData = { ...newData, "m3_id": "", "m3List": [] };
                }
                if (valueList[0].m4_id && valueList[0].m4_value.length > 0) {
                    newData = { ...newData, "m4_id": valueList[0].m4_id, "m4List": valueList[0].m4_value };
                }
                else {
                    newData = { ...newData, "m4_id": "", "m4List": [] };
                }


                state.serviceLineDetails.procedures[rowId] = newData;
            } else {
                let newData = { ...serviceLineDetails?.procedures[rowId] };
                newData = {
                    ...newData, [field]: '',
                    "pos_id": '',
                    "tos_id": '', "charges": '0',
                    "fee": '0',
                    'proced_description': "",
                    "pos_id_List": [],
                    "tos_id_List": [],
                    [field + 'List']: valueList
                }
                state.serviceLineDetails.procedures[rowId] = newData;
            }

            /**
           * modifier validation done here
           */
            let { modifiervalidate,
                modifier1,
                modifier2,
                modifier3,
                modifier4 } = validateModifier(state.serviceLineDetails.procedures);

            state.ModifiersValidated = modifiervalidate;
            state.inputM1Validated = modifier1;
            state.inputM2Validated = modifier2;
            state.inputM3Validated = modifier3;
            state.inputM4Validated = modifier4;
            /**
               * changing value of service line info array value
               */

            let index = serviceLineData.findIndex((item) => item.claim_pk === claim_pk);
            if (index >= 0) {
                state.serviceLineData[index] = state.serviceLineDetails;
            }
        },

        /**
         * for update pos list in procedure
         * @param {*} state 
         * @param {*} actions 
         */
        updatePOSList: (state, actions) => {
            let { serviceLineDetails, serviceLineData } = current(state);
            let { valueList, rowId, claimPK } = actions.payload;
            let claim_pk = claimPK ? claimPK : "add_new";
            let newData = { ...serviceLineDetails?.procedures[rowId] };
            if (valueList.length > 0) {
                let posList = [{
                    id: valueList[0]?.id,
                    label: valueList[0]?.drop_down_name,
                    name: valueList[0]?.drop_down_name,
                }]
                newData = { ...newData, 'pos_id': valueList[0].id };
                newData = { ...newData, 'pos_id_List': posList };
            }
            else {
                newData = { ...newData, 'pos_id': "" };
                newData = { ...newData, 'pos_id_List': [] };
            }

            state.serviceLineDetails.procedures[rowId] = newData;

            /**
               * changing value of service line info array value
               */

            let index = serviceLineData.findIndex((item) => item.claim_pk === claim_pk);
            if (index >= 0) {
                state.serviceLineData[index] = state.serviceLineDetails;
            }
        },

        /**
         * for update tos list in procedure
         * @param {*} state 
         * @param {*} actions 
         */
        updateTOSList: (state, actions) => {
            let { serviceLineDetails, serviceLineData } = current(state);
            let { valueList, rowId, claimPK } = actions.payload;
            let claim_pk = claimPK ? claimPK : "add_new";
            let newData = { ...serviceLineDetails?.procedures[rowId] };
            if (valueList.length > 0) {
                let posList = [{
                    id: valueList[0]?.id,
                    label: valueList[0]?.drop_down_name,
                    name: valueList[0]?.drop_down_name,
                }]
                newData = { ...newData, 'tos_id': valueList[0].id };
                newData = { ...newData, 'tos_id_List': posList };
            }
            else {
                newData = { ...newData, 'tos_id': "" };
                newData = { ...newData, 'tos_id_List': [] };
            }

            state.serviceLineDetails.procedures[rowId] = newData;

            /**
               * changing value of service line info array value
               */

            let index = serviceLineData.findIndex((item) => item.claim_pk === claim_pk);
            if (index >= 0) {
                state.serviceLineData[index] = state.serviceLineDetails;
            }
        },

        /**
         * for update modifier list in procedure
         * @param {*} state 
         * @param {*} actions 
         */
        updateModifierList: (state, actions) => {
            let { serviceLineDetails, serviceLineData } = current(state);
            let { valueList, name, rowId, claimPK } = actions.payload;
            let claim_pk = claimPK ? claimPK : "add_new";
            let newData = { ...serviceLineDetails?.procedures[rowId] };
            if (valueList.length > 0) {
                newData = { ...newData, [name]: valueList[0].id };
                newData = { ...newData, [name.split('_')[0] + 'List']: valueList };
            }
            else {
                newData = { ...newData, [name]: "" };
                newData = { ...newData, [name.split('_')[0] + 'List']: [] };
            }

            state.serviceLineDetails.procedures[rowId] = newData;

            /**
            * modifier validation done here
            */
            let { modifiervalidate,
                modifier1,
                modifier2,
                modifier3,
                modifier4 } = validateModifier(state.serviceLineDetails.procedures);

            state.ModifiersValidated = modifiervalidate;
            state.inputM1Validated = modifier1;
            state.inputM2Validated = modifier2;
            state.inputM3Validated = modifier3;
            state.inputM4Validated = modifier4;

            /**
               * changing value of service line info array value
               */

            let index = serviceLineData.findIndex((item) => item.claim_pk === claim_pk);
            if (index >= 0) {
                state.serviceLineData[index] = state.serviceLineDetails;
            }
        },

        /**
         * update procedure other details
         * @param {*} state 
         * @param {*} actions 
         * @returns 
         */
        updateProcedureOtherDetails: (state, actions) => {
            let { serviceLineDetails, serviceLineData, professionalClaimDetails, professionalClaim } = current(state);
            let { value, field, rowId, claimPK } = actions.payload;
            let claim_pk = claimPK ? claimPK : "add_new";
            let newData = serviceLineDetails?.procedures[rowId];
            let formatted_value = "";
            let serviceFromDate = "";
            let serviceToDate = "";
            let professionClaimValue = "";
            if (value) {
                if (field == "charges" || field == "fee") {
                    formatted_value = String(value.trim())
                        .replace(/[^0-9.]/g, '')  // Remove all non-numeric and non-period characters
                        .replace(/(\..*?)\./g, '$1')  // Remove all extra decimal points
                        .replace(/(\.\d{2})\d*/g, '$1')  // Allow only two digits after the first decimal point
                        .substring(0, 9);  // Limit the length to 9 characters
                }
                else if (field == "fee_units") {
                    formatted_value = String(value.trim()).replace(/[^0-9]/g, '').substring(0, 9);
                }
                else if (field === "drug_code") {
                    formatted_value = String(value.trim()).replace(/[^\d]/g, '').substring(0, 11);
                } else if (field.endsWith("unit_value") || field === "anesthesia") {
                    formatted_value = String(value.trim()).replace(/^0|[^\d]/g, '').substring(0, 9);
                } else if (field === "dx_pointers") {
                    // Validate if user has selected a valid icd for the corresponding icd pointer
                    if (!validateIsValidDxPointer(serviceLineDetails, [...new Set(value.toUpperCase().replace(/[^A-L]/g, ''))].join(''))) {
                        alert("Invalid DX Pointer Combination.");
                        return;
                    }

                    formatted_value = [...new Set(value.toUpperCase().replace(/[^A-L]/g, ''))].join('');
                }
                else if (field == "dos_from" || field == "dos_to") {
                    formatted_value = format(value, "yyyy-MM-dd");
                } else {
                    formatted_value = value;
                }
                if (field === "responsibility_type") {
                    if (value == 5 && serviceLineDetails?.procedures[rowId].balance > 0) {
                        state.notifyMessage = i18n.t('errorMessages.paidErrorMessage');
                        state.showNotification = true;
                        state.statusTag = "error";
                        return
                    }

                    if (value == 5 && serviceLineDetails?.procedures[rowId].balance == undefined) {
                        state.notifyMessage = i18n.t('errorMessages.paidErrorMessage');
                        state.showNotification = true;
                        state.statusTag = "error";
                        return
                    }
                }

                if (field === "fee" || field === "fee_units") {
                    let pRow = serviceLineDetails?.procedures[rowId];
                    let feeValue = 0, feeUnitsValue = 0, chargeValue = 0;
                    if (field === "fee") {
                        feeValue = formatted_value;
                        feeUnitsValue = pRow.fee_units;
                    }
                    else if (field === "fee_units") {
                        feeValue = pRow.fee;
                        feeUnitsValue = value;
                    }
                    chargeValue = (decimalConverter(feeValue, 2) * decimalConverter(feeUnitsValue, 2));
                    chargeValue = isNaN(chargeValue) ? "0.00" : decimalConverter(chargeValue, 2);
                    newData = { ...newData, [field]: formatted_value, ["charges"]: chargeValue };
                }
                if (field == "dos_from") {
                    newData = { ...newData, [field]: formatted_value, ["dos_to"]: formatted_value };
                    serviceFromDate = getServiceDateFrom(formatted_value, serviceLineDetails?.procedures, rowId);
                    serviceToDate = getServiceDateTo(formatted_value, serviceLineDetails?.procedures, rowId);
                    professionClaimValue = {
                        ...professionalClaimDetails,
                        "service_from_date": serviceFromDate,
                        "service_to_date": serviceToDate
                    }
                }
                if (field == "dos_to") {
                    newData = { ...newData, ["dos_to"]: formatted_value };
                    serviceToDate = getServiceDateTo(formatted_value, serviceLineDetails?.procedures, rowId);
                    professionClaimValue = {
                        ...professionalClaimDetails,
                        "service_to_date": format(serviceToDate, "yyyy-MM-dd")
                    }
                }
                else {
                    newData = { ...newData, [field]: formatted_value };
                    if (field === "claim_status") {
                        newData = { ...newData, ["claim_sub_status"]: "" }
                    }
                }

            } else if (!value) {
                newData = { ...newData, [field]: "" };
                if (field === "claim_status") {
                    newData = { ...newData, ["claim_sub_status"]: "" }
                }
            }

            state.serviceLineDetails.procedures[rowId] = newData;

            /**
               * changing value of service line info array value
               */

            let index = serviceLineData.findIndex((item) => item.claim_pk === claim_pk);
            if (index >= 0) {
                state.serviceLineData[index] = state.serviceLineDetails;
            }
            if (professionClaimValue) {
                state.professionalClaimDetails = professionClaimValue;
                /**
                 * changing professional claim details
                 */

                let index1 = professionalClaim.findIndex((item) => item.claim_pk === claim_pk);
                if (index1 >= 0) {
                    state.professionalClaim[index] = professionClaimValue;
                }
            }

        },
        /**
         * function to change icd type to ICD1 or ICD2 in service line
         * @param {*} state 
         * @param {*} actions 
         */
        ICDTypeChange: (state, actions) => {
            let { serviceLineData, serviceLineDetails } = current(state);
            let { field, value, claimPK } = actions.payload;
            let claim_pk = claimPK ? claimPK : "add_new";
            let updateValue = {
                ...serviceLineDetails,
                [field]: value
            }

            /**immidetly updating service line details */
            state.serviceLineDetails = updateValue;

            /**
              * changing value of service line info array value
              */

            let index = serviceLineData.findIndex((item) => item.claim_pk === claim_pk);
            if (index >= 0) {
                state.serviceLineData[index] = updateValue;
            }
        },
        /**
         * function to change diagnosis variables
         * @param {*} state 
         * @param {*} actions 
         */
        HandleICDChange: (state, actions) => {
            let { serviceLineData, serviceLineDetails } = current(state);
            let { field, valueList, claimPK } = actions.payload;
            let claim_pk = claimPK ? claimPK : "add_new";
            // Validate if the request icd already in the state OR User trying to add icd item with blank slots in between
            let isIcdDuplicate = false;
            let allSlotValues = {};
            const labelToCheck = valueList[0]?.label;

            if (labelToCheck) {
                for (let i = 1; i <= 12; i++) {
                    const diagnosisKey = `diagnosis${i}`;

                    allSlotValues[diagnosisKey] = serviceLineDetails[diagnosisKey];

                    if (serviceLineDetails[diagnosisKey] === labelToCheck && diagnosisKey !== field) {
                        isIcdDuplicate = true;
                        break;
                    }
                }
            }

            allSlotValues[field] = labelToCheck;

            if (isIcdDuplicate || checkForGapsBetweenIcd(allSlotValues)) {
                const message = isIcdDuplicate
                    ? "You are trying to add an ICD that is already in the list."
                    : "Blank slots between ICDs are not allowed.";
                alert(message);
                return;
            }

            let label = ''
            if (Array.isArray(valueList) && valueList.length > 0) {
                label = valueList[0].label;
            }

            let updateValue = {
                ...serviceLineDetails,
                [field]: label, [field + "List"]: valueList
            }

            /**
             * when adding diganosis from history table creating dx pointer value and updating procedure
             */
            let dxPointer = updateDXpointer(updateValue);
            const updatedProcedures = updateValue?.procedures?.map((procedure) => ({
                ...procedure,
                dx_pointers: dxPointer
            }));

            updateValue = {
                ...updateValue,
                procedures: updatedProcedures
            }

            /**immidetly updating service line details */
            state.serviceLineDetails = updateValue;

            /**
             * diagnosis duplicate check and validation done here
             */
            let { dgnValid, isDuplicate, isValidted } = validateDiagnosis(updateValue, state.diagnosisValidation);

            state.diagnosisValidation = dgnValid;
            state.checkForDuplicatediagnosis = isDuplicate;
            state.diagnosisesValidated = isValidted;
            /**
              * changing value of service line info array value
              */

            let index = serviceLineData.findIndex((item) => item.claim_pk === claim_pk);
            if (index >= 0) {
                state.serviceLineData[index] = updateValue;
            }
        },

        /**
         * @description Add new procedure row item on add new btn click
         * @param {*} state 
         * @returns 
         */
        addNewProcedureRow: (state, actions) => {
            let { serviceLineDetails, serviceLineData, billingInfoDetails,professionalClaimDetails,claimSubStatus} = current(state);
            let { claimPK,paymentDone} = actions.payload;
            let claim_pk = claimPK ? claimPK : "add_new";

            const getLastProcedureRowDos = (dos_type) => {
                const lastProcedure = serviceLineDetails?.procedures?.[0];
                const dos_from = lastProcedure?.dos_from;
                const dos_to = lastProcedure?.dos_to;

                if (dos_from && dos_to) {
                    return dos_type === 'dos_from' ? dos_from : dos_type === 'dos_to' ? dos_to : "";
                }
                return "";
            }

            const getDxPointers = () => {
                let dxPointers = "";
                const pointerMapping = {
                    "1": "A", "2": "B", "3": "C", "4": "D", "5": "E", "6": "F",
                    "7": "G", "8": "H", "9": "I", "10": "J", "11": "K", "12": "L"
                };

                for (let item = 1; item <= 12; item++) {
                    const diagnosisKey = `diagnosis${item}`;
                    if (serviceLineDetails?.[diagnosisKey]) {
                        dxPointers += pointerMapping[item] || "";
                    }
                }

                return dxPointers;
            }

            const getResponsibility = () => {
                if (!billingInfoDetails?.payerInsuranceList || billingInfoDetails?.payerInsuranceList.length === 0) {
                    return RESPONSIBILITY_TYPES.find(item => item.name === "Patient")?.id || "";
                }

                const sortedList = billingInfoDetails?.payerInsuranceList?.slice().sort((a, b) => a.priority - b.priority);
                return Number(sortedList[0]?.priority) || "";
            }

            const getClaimStatusAndSubStatus = () => ({
                claim_status: paymentDone ? null : professionalClaimDetails?.claim_status,
                claim_sub_status: paymentDone ? null : professionalClaimDetails?.claim_sub_status,
            });

            state.serviceLineDetails.procedures = [...serviceLineDetails.procedures, {
                ...newProcedureRowItem,
                id: `New-${crypto?.randomUUID()}`,
                dos_from: getLastProcedureRowDos('dos_from'),
                dos_to: getLastProcedureRowDos('dos_to'),
                dx_pointers: getDxPointers(),
                responsibility_type: getResponsibility(),
                ...getClaimStatusAndSubStatus()
            }]


            if (claimSubStatus.length > 0 && professionalClaimDetails.claim_status) {
                let filterData = claimSubStatus?.filter(item => item.parent_claim_status_ids?.includes(professionalClaimDetails.claim_status));
                state.serviceLineDetails.claim_sub_status[serviceLineDetails.procedures.length] = filterData;
            }

            /**
             * modifier validating
             */
            let { modifiervalidate,
                modifier1,
                modifier2,
                modifier3,
                modifier4 } = validateModifier(state.serviceLineDetails.procedures);

            state.ModifiersValidated = modifiervalidate;
            state.inputM1Validated = modifier1;
            state.inputM2Validated = modifier2;
            state.inputM3Validated = modifier3;
            state.inputM4Validated = modifier4;

            let index = serviceLineData.findIndex((item) => item.claim_pk === claim_pk);
            if (index >= 0) {
                state.serviceLineData[index] = state.serviceLineDetails;
            }
            // Once the new procedure row is updated to the state move the scroll position to the newly added procedure row
            setTimeout(() => {
                document.getElementById(`procedure_row_${serviceLineDetails?.procedures[serviceLineDetails?.procedures.length - 1]?.id}`)?.scrollIntoView({
                    behavior: 'smooth'
                });
            }, 200);
        },

        /**
         * @description Remove a procedure row item from the procedure rows
         * @param {*} state 
         * @returns 
         */
        removeProcedureRowItem: (state, actions) => {
            let { serviceLineDetails, serviceLineData } = current(state);
            let { claimPK, index, procedureId } = actions.payload;
            let claim_pk = claimPK ? claimPK : "add_new";

            // Check if the procedure ID matches and remove the item using filter
            if (serviceLineDetails.procedures[index]?.id === procedureId) {
                serviceLineDetails = {
                    ...serviceLineDetails,
                    claim_sub_status: {
                        ...serviceLineDetails.claim_sub_status,
                        [index]: [] // Correctly update the specific index of claim_sub_status
                    },
                    procedures: serviceLineDetails.procedures.filter((_, i) => i !== index)
                };
            }

            state.serviceLineDetails = serviceLineDetails;

            let itemIndexInServiceLineDataState = serviceLineData.findIndex((item) => item.claim_pk === claim_pk);
            if (itemIndexInServiceLineDataState >= 0) {
                state.serviceLineData[itemIndexInServiceLineDataState] = state.serviceLineDetails;
            }

            let { modifiervalidate,
                modifier1,
                modifier2,
                modifier3,
                modifier4 } = validateModifier(state.serviceLineDetails.procedures);

            state.ModifiersValidated = modifiervalidate;
            state.inputM1Validated = modifier1;
            state.inputM2Validated = modifier2;
            state.inputM3Validated = modifier3;
            state.inputM4Validated = modifier4;
        },

        /**
         * show error message
         * @param {*} state 
         * @param {*} actions 
         */
        showNotifications: (state, actions) => {
            state.notifyMessage = actions.payload.message;
            state.showNotification = actions.payload.isopen;
            state.statusTag = actions.payload.status;

        },

        /**
         *show modal windows 
         * @param {s} state 
         * @param {*} actions 
         */
        showModalWindow: (state, actions) => {
            let { key, value, claimPK } = actions.payload;
            let { professionalClaimDetails, professionalClaim } = current(state);
            let claim_pk = claimPK ? claimPK : "add_new";
            state[key] = actions.payload.value;

            if (key === "patientAlertModal" && value === false) {
                state.alertMessage = ""
            }

            if (key === "inactiveClaimModal" && value === false && claimPK) {
                /**on inactive modal hide need to change inactive to false and active to true */
                let updateValue = {
                    ...professionalClaimDetails,
                    "claim_inactive": false,
                    "active": true
                }

                /**
             * updating profession claim details immediately
             */
                state.professionalClaimDetails = updateValue;


                /**
                 * changing value of professional claim array value
                 */

                let index = professionalClaim.findIndex((item) => item.claim_pk === claim_pk);
                if (index >= 0) {
                    state.professionalClaim[index] = updateValue;
                }
            }

            if (key === "activeClaimModal" && value === false) {
                let updateValue = {
                    ...professionalClaimDetails,
                    "claim_inactive": true,
                    "active": false
                }

                /**
             * updating profession claim details immediately
             */
                state.professionalClaimDetails = updateValue;


                /**
                 * changing value of professional claim array value
                 */

                let index = professionalClaim.findIndex((item) => item.claim_pk === claim_pk);
                if (index >= 0) {
                    state.professionalClaim[index] = updateValue;
                }
            }
        },
        /**
         * if save or save close have waring pop it need to manage from main component thats why we are storing claim details from the save response
         * @param {*} state 
         * @param {*} action 
         */
        saveClaimIDWaringPopUP: (state, action) => {
            let item = {
                "claimId": action.payload.claimId,
                "claimPK": action.payload.claimPK,
                "isClose": action.payload.isClose,
                "isSave": action.payload.isSave
            }

            state.savedClaimDetails = item;
        },
        /**
         * SHOW FORM WARNING MESSAGE ON SAVE 
         * @param {*} state 
         * @param {*} actions 
         */
        setFormWarningMessages: (state, actions) => {
            state.formWarningMessage = actions.payload;
        },
        /**
         * SHOW FROM WARNING MESSAGE ON TOP OF THE COMPONENT
         * @param {*} state 
         * @param {*} actions 
         */
        setFormWaringData: (state, actions) => {
            state.formWarningData = actions.payload;
        },
        /**
         * to reset error showing varibale to null after show the error
         * @param {*} state 
         */
        resetNotifyWindow: (state) => {
            state.notifyMessage = "";
            state.showNotification = false;
            state.statusTag = "";
        },

        setActiveSubTab: (state, actions) => {
            state.activeSubTab = actions.payload;
        },

        /**
         * remove claim details of seleted or existing patient
         * @param {*} state 
         * @param {*} action 
         */
        removeClaimDetails: (state, action) => {
            let claim_pk = action.payload ? action.payload : "add_new";
            state.CallInsurance = initialState.CallInsurance;
            state.claimPK = "";
            state.claim_pk = "";
            state.diagnosis_pk = "";
            state.billing_info_pk = "";
            state.addition_info_pk = "";
            state.patient_PK = "";
            state.professionalClaimDetails = initialState.professionalClaimDetails;
            state.billingInfoDetails = initialState.billingInfoDetails;
            state.serviceLineDetails = initialState.serviceLineDetails;
            state.diagnosisValidation = initialState.diagnosisValidation;
            state.additionalClaimDetails = initialState.additionalClaimDetails;
            state.payerInsuranceList = [];
            state.primaryInsuranceDataList = [];
            state.secondaryInsuranceDataList = [];
            state.tertiaryInsuranceDataList = [];
            state.primarypolicyId = "";
            state.insurancePrimary = "";
            state.secondarypolicyId = "";
            state.insurancesecondary = "";
            state.tertiarypolicyId = "";
            state.insuranceTertiary = "";
            state.ModifiersValidated = [];
            state.inputM1Validated = [];
            state.inputM2Validated = [];
            state.inputM3Validated = [];
            state.inputM4Validated = [];
            state.checkForDuplicatediagnosis = false;
            state.diagnosisesValidated = true;
            state.infoLoaded = false;
            state.patientSelectedData = [];
            state.patientAdvanceSearchData = [];
            state.selectedPatient = "";
            state.insuranceCompleted = false;
            state.formWarningMessage = "";
            state.formWarningData = [];
            state.formWarningStatus = false;
            state.activeSubTab = TAB1

            let index = state.open_pks.findIndex((item) => item == claim_pk);
            state.open_pks.splice(index, 1);
            let professionalIndex = state.professionalClaim.findIndex((item) => item.claim_pk == claim_pk);
            state.professionalClaim.splice(professionalIndex, 1);
            let billingIndex = state.billingInfo.findIndex((item) => item.claim_pk == claim_pk);
            state.billingInfo.splice(billingIndex, 1);
            let serviceIndex = state.serviceLineData.findIndex((item) => item.claim_pk == claim_pk);
            state.serviceLineData.splice(serviceIndex, 1);
            let additionalIndex = state.additionalClaimData.findIndex((item) => item.claim_pk == claim_pk);
            state.additionalClaimData.splice(additionalIndex, 1);
            let pksIndex = state.pks.findIndex((item) => item.claim_pk == claim_pk);
            state.pks.splice(pksIndex, 1);

        },

        /**
         * getting claim details
         * @param {*} state 
         * @param {*} actions 
         */
        getClaimDetailsByClaimPK: (state, actions) => {
            let claim_pk = actions.payload ? actions.payload : "add_new";
            let { professionalClaim, billingInfo, serviceLineData, additionalClaimData } = current(state);
            let professionalDetails = professionalClaim.filter((item) => item.claim_pk == claim_pk);
            let billingDetails = billingInfo.filter((item) => item.claim_pk == claim_pk);
            let serviceDetails = serviceLineData.filter((item) => item.claim_pk == claim_pk);
            let additionalDetails = additionalClaimData.filter((item) => item.claim_pk == claim_pk);
            /**setting to single state */
            state.professionalClaimDetails = professionalDetails.length > 0 ? professionalDetails[0] : initialState.professionalClaimDetails;
            state.billingInfoDetails = billingDetails.length > 0 ? billingDetails[0] : initialState.billingInfoDetails;
            state.serviceLineDetails = serviceDetails.length > 0 ? serviceDetails[0] : initialState.serviceLineDetails;
            state.additionalClaimDetails = additionalDetails.length > 0 ? additionalDetails[0] : initialState.additionalClaimDetails;
            state.claimPK = actions.payload ? actions.payload : "";
            state.claim_pk = actions.payload ? actions.payload : "";
            state.diagnosis_pk = professionalDetails.length > 0 ? professionalDetails[0].pks.diagnosises_pk : "";
            state.billing_info_pk = professionalDetails.length > 0 ? professionalDetails[0].pks.billing_info_pk : "";
            state.addition_info_pk = professionalDetails.length > 0 ? professionalDetails[0].pks.additional_claim_info_pk : "";
            state.patient_PK = professionalDetails.length > 0 ? professionalDetails[0].patient : "";

            /**
          * modifier validation done here
          */
            let { modifiervalidate,
                modifier1,
                modifier2,
                modifier3,
                modifier4
            } = validateModifier(serviceDetails.length > 0 ? serviceDetails[0].procedures : []);

            state.ModifiersValidated = modifiervalidate;
            state.inputM1Validated = modifier1;
            state.inputM2Validated = modifier2;
            state.inputM3Validated = modifier3;
            state.inputM4Validated = modifier4;

            /**
            * setting form waring error on popup
            */
            let serverWarings = setWarningFromServer(professionalDetails.length > 0 ? professionalDetails[0] : {});
            state.formWarningData = serverWarings;

            /**
            * diagnosis duplicate check and validation done here
            */
            let { dgnValid, isDuplicate, isValidted } = validateDiagnosis(serviceDetails.length > 0 ? serviceDetails[0] : initialState.serviceLineDetails, state.diagnosisValidation);

            state.diagnosisValidation = dgnValid;
            state.checkForDuplicatediagnosis = isDuplicate;
            state.diagnosisesValidated = isValidted;
        },
        /**
         * adding new diganosis variable from patient history table
         * @param {*} state 
         * @param {*} action 
         */
        addDiganosisVariablePatientHistory: (state, action) => {
            let { item, claimPK } = action.payload;
            let claim_pk = claimPK ? claimPK : "add_new";
            let { serviceLineDetails, serviceLineData } = current(state);
            let icdToAdd = { label: item[0].id, name: item[0].value }
            let availableSlotInICDList = null;
            for (let i = 1; i <= 12; i++) {
                const key = `diagnosis${i}`;
                if (serviceLineDetails?.[key] === "") {
                    availableSlotInICDList = key;
                    break;
                }
            }

            let updateValue = {
                ...serviceLineDetails,
                [availableSlotInICDList]: icdToAdd.label.toString(),
                [availableSlotInICDList + 'List']: [icdToAdd]
            }

            /**
             * when adding diganosis from history table creating dx pointer value and updating procedure
             */
            const dxPointers = updateDXpointer(updateValue);
            const updatedProcedures = updateValue?.procedures?.map((procedure) => ({
                ...procedure,
                dx_pointers: dxPointers
            }));

            updateValue = {
                ...updateValue,
                procedures: updatedProcedures
            }

            /**immidetly updating service line details */
            state.serviceLineDetails = updateValue;

            /**
             * diagnosis duplicate check and validation done here
             */
            let { dgnValid, isDuplicate, isValidted } = validateDiagnosis(updateValue, state.diagnosisValidation);

            state.diagnosisValidation = dgnValid;
            state.checkForDuplicatediagnosis = isDuplicate;
            state.diagnosisesValidated = isValidted;
            /**
              * changing value of service line info array value
              */

            let index = serviceLineData.findIndex((item) => item.claim_pk === claim_pk);
            if (index >= 0) {
                state.serviceLineData[index] = updateValue;
            }
        },
        /**
         * removing existing or added diganosis variable from patient history table
         * @param {*} state 
         * @param {*} action 
         */
        removeDiganosisVariablePatientHistory: (state, action) => {
            let { item, claimPK } = action.payload;
            let claim_pk = claimPK ? claimPK : "add_new";
            let { serviceLineDetails, serviceLineData } = current(state);
            let removedKey = null;
            for (let i = 1; i <= 12; i++) {
                const key = `diagnosis${i}`;
                if (serviceLineDetails[key] == item[0].id) {
                    removedKey = key;
                    break;
                }
            }
            // remove the item from the state
            let updateValue = {
                ...serviceLineDetails,
                [removedKey]: '',
                [removedKey + 'List']: []
            }

            // check if any key has value that comes after the removed icd/dx index
            function findKeysAfterTheRemovedItem(obj, removedKey) {
                let keys = Object.keys(obj);
                let index = keys.indexOf(removedKey);
                let result = [];
                for (let i = index; i < keys.length; i++) {
                    if (obj[keys[i]] !== null && obj[keys[i]] !== '' && (Array.isArray(obj[keys[i]]) ? obj[keys[i]].length : true)) {
                        result.push(keys[i]);
                    }
                }
                return result;
            }
            const icdItemComesAfterRemovedSlot = findKeysAfterTheRemovedItem(updateValue, removedKey);
            // shift all the icds to the unoccupied slot in the list
            if (icdItemComesAfterRemovedSlot.length) {
                icdItemComesAfterRemovedSlot.forEach((icdToSwitchSlot) => {
                    if (icdToSwitchSlot.startsWith('diagnosis') && icdToSwitchSlot.length <= 11) {
                        let availableSlotInICDList1 = null;
                        for (let i = 1; i <= 12; i++) {
                            const key = `diagnosis${i}`;
                            if (updateValue[key] === "") {
                                availableSlotInICDList1 = key;
                                break;
                            }
                        }
                        let tempStoreICDToShift = updateValue[icdToSwitchSlot]
                        let tempStoreICDListToShift = updateValue[`${icdToSwitchSlot}List`];
                        //shift the values
                        updateValue = {
                            ...updateValue,
                            [availableSlotInICDList1]: tempStoreICDToShift.toString(),
                            [availableSlotInICDList1 + 'List']: tempStoreICDListToShift
                        }

                        // remove the shifted value
                        updateValue = {
                            ...updateValue,
                            [icdToSwitchSlot]: '',
                            [`${icdToSwitchSlot}List`]: []
                        }
                    }
                })
            }

            /**
             * when removing
             *  diganosis from history table creating dx pointer value and updating procedure
             */
            const dxPointers = updateDXpointer(updateValue);
            const updatedProcedures = updateValue?.procedures?.map((procedure) => ({
                ...procedure,
                dx_pointers: dxPointers
            }));

            updateValue = {
                ...updateValue,
                procedures: updatedProcedures
            }

            /**immidetly updating service line details */
            state.serviceLineDetails = updateValue;

            /**
             * diagnosis duplicate check and validation done here
             */
            let { dgnValid, isDuplicate, isValidted } = validateDiagnosis(updateValue, state.diagnosisValidation);

            state.diagnosisValidation = dgnValid;
            state.checkForDuplicatediagnosis = isDuplicate;
            state.diagnosisesValidated = isValidted;
            /**
              * changing value of service line info array value
              */

            let index = serviceLineData.findIndex((item) => item.claim_pk === claim_pk);
            if (index >= 0) {
                state.serviceLineData[index] = updateValue;
            }

        },

        /**
         * add cpt from patient history table
         * @param {*} state 
         * @param {*} action 
         */
        addCPTFrompatientHistoryTable: (state, action) => {
            let { item, claimPK, paymentDone } = action.payload;
            let { patientHistoryCPT, serviceLineDetails, billingInfoDetails, serviceLineData,professionalClaimDetails,claimSubStatus} = current(state);
            let claim_pk = claimPK ? claimPK : "add_new";
            let tmpProcedure = { ...procedure };
            const selectedCPT = patientHistoryCPT.filter(ele => ele.id == item[0].id);

            const getLastProcedureRowDos = (dos_type) => {
                const lastProcedure = serviceLineDetails?.procedures?.[serviceLineDetails?.procedures.length - 1];
                const dos_from = lastProcedure?.dos_from;
                const dos_to = lastProcedure?.dos_to;

                if (dos_from && dos_to) {
                    return dos_type === 'dos_from' ? dos_from : dos_type === 'dos_to' ? dos_to : "";
                }
                return "";
            }

            const getResponsibility = () => {
                if (!billingInfoDetails?.payerInsuranceList || billingInfoDetails?.payerInsuranceList.length === 0) {
                    return RESPONSIBILITY_TYPES.find(item => item.name === "Patient")?.id || "";
                }

                const sortedList = billingInfoDetails?.payerInsuranceList?.slice().sort((a, b) => a.priority - b.priority);
                return Number(sortedList[0]?.priority) || "";
            }

            if (selectedCPT.length > 0) {
                if (selectedCPT[0].id) {
                    tmpProcedure.cpt_hcpcs = selectedCPT[0]?.id;
                    tmpProcedure.cpt_hcpcsList = [{ id: selectedCPT[0]?.id, name: selectedCPT[0]?.name }];
                } else {
                    tmpProcedure.cpt_hcpcs = "";
                    tmpProcedure.cpt_hcpcsList = [];
                }

                if (selectedCPT[0].pos) {
                    tmpProcedure.pos_id = selectedCPT[0]?.pos?.id ? selectedCPT[0]?.pos?.id : "";
                    tmpProcedure.pos_id_List = selectedCPT[0]?.pos?.id ? [selectedCPT[0]?.pos] : [];
                } else {
                    tmpProcedure.pos_id = "";
                    tmpProcedure.pos_id_List = [];
                }

                tmpProcedure.fee_units = selectedCPT[0]?.unit ? selectedCPT[0]?.unit : 0;
                tmpProcedure.fee = selectedCPT[0]?.fee ? decimalConverter(selectedCPT[0]?.fee, 2) : "0.00";
                tmpProcedure.charges =
                    decimalConverter(selectedCPT[0]?.fee ? selectedCPT[0]?.fee : 0, 2) *
                    parseInt(selectedCPT[0]?.unit ? selectedCPT[0]?.unit : 0);
            }

            const getClaimStatusAndSubStatus = () => ({
                claim_status: paymentDone ? null : professionalClaimDetails?.claim_status,
                claim_sub_status: paymentDone ? null : professionalClaimDetails?.claim_sub_status,
            });

            const getDxPointers = () => {
                let dxPointers = "";
                const pointerMapping = {
                    "1": "A", "2": "B", "3": "C", "4": "D", "5": "E", "6": "F",
                    "7": "G", "8": "H", "9": "I", "10": "J", "11": "K", "12": "L"
                };

                for (let item = 1; item <= 12; item++) {
                    const diagnosisKey = `diagnosis${item}`;
                    if (serviceLineDetails?.[diagnosisKey]) {
                        dxPointers += pointerMapping[item] || "";
                    }
                }

                return dxPointers;
            }

            state.serviceLineDetails.procedures = [...serviceLineDetails.procedures, {
                ...tmpProcedure,
                id: `New-${crypto?.randomUUID()}`,
                dos_from: getLastProcedureRowDos('dos_from'),
                dos_to: getLastProcedureRowDos('dos_to'),
                dx_pointers: getDxPointers(),
                responsibility_type: getResponsibility(),
                ...getClaimStatusAndSubStatus()

            }]


            if (claimSubStatus.length > 0 && professionalClaimDetails.claim_status) {
                let filterData = claimSubStatus?.filter(item => item.parent_claim_status_ids?.includes(professionalClaimDetails.claim_status));
                state.serviceLineDetails.claim_sub_status[serviceLineDetails.procedures.length] = filterData;
            }

            /**
             * modifier validating
             */
            let { modifiervalidate,
                modifier1,
                modifier2,
                modifier3,
                modifier4 } = validateModifier(state.serviceLineDetails.procedures);

            state.ModifiersValidated = modifiervalidate;
            state.inputM1Validated = modifier1;
            state.inputM2Validated = modifier2;
            state.inputM3Validated = modifier3;
            state.inputM4Validated = modifier4;

            let index = serviceLineData.findIndex((item) => item.claim_pk === claim_pk);
            if (index >= 0) {
                state.serviceLineData[index] = state.serviceLineDetails;
            }
            // Once the new procedure row is updated to the state move the scroll position to the newly added procedure row
            setTimeout(() => {
                document.getElementById(`procedure_row_${serviceLineDetails?.procedures[serviceLineDetails?.procedures.length - 1]?.id}`)?.scrollIntoView({
                    behavior: 'smooth'
                });
            }, 200);

        },
        /**
         * When a claim get un-locked from page lock feature, and if that claim is currently in the users session, then below
         * reducer func handles the logic of un-locking that particular claim
         * @param {*} state 
         * @param {*} action contains the claimPk 
         */
        handleClaimPageUnlock: (state, action) => {
            let { professionalClaimDetails, professionalClaim } = current(state);
            const claimPk = action.payload?.claimPK;
            if (claimPk) {
                let updateValue = {
                    ...professionalClaimDetails,
                    locked_by_name: "",
                    locked: false,
                    claim_editable: true
                }
                /**
                 * updating profession claim details immediately
                 */
                state.professionalClaimDetails = updateValue;
                /**
                 * changing value of professional claim array value
                 */
                let index = professionalClaim.findIndex((item) => item.claim_pk === claimPk);
                if (index >= 0) {
                    state.professionalClaim[index] = updateValue;
                }
            }
        },

        filterClaimSubStatus: (state, actions) => {
            let { claimSubStatus, serviceLineData } = current(state);
            let { value, propindex, claimPK } = actions.payload;
            let claim_pk = claimPK ? claimPK : "add_new";

            if (claimSubStatus.length > 0) {
                let filterData = claimSubStatus?.filter(item => item.parent_claim_status_ids?.includes(value));
                state.serviceLineDetails.claim_sub_status[propindex] = filterData;
                let index = serviceLineData.findIndex((item) => item.claim_pk === claim_pk);
                if (index >= 0) {
                    state.serviceLineData[index] = state.serviceLineDetails;
                }
            }
        }
    },
    extraReducers: (builder) => {

        /*****************************/
        builder
            .addCase(getUserOpenedClaimModuleTabs.pending, (state) => {
                state.dataLoading = true;
            })
        builder
            .addCase(getUserOpenedClaimModuleTabs.fulfilled, (state, action) => {
                state.dataLoading = false;
                let currentState = current(state);
                if (action?.payload?.data?.data?.length > 0) {
                    state.claimParentTabs = action.payload?.data?.data?.map((item) => ({
                        ...item,
                        is_opened: item.is_opend
                    }))

                    let openedTab = action.payload?.data?.data?.find((element) => {
                        return element.is_opend;
                    });

                    //New Profession claim retaining
                    let isProfessional = action.payload?.data?.data?.find((element) => {
                        return element.pk === "add_new";
                    });


                    if (isProfessional) {
                        state.selectedClaimType = "professional";
                    }
                    else {
                        state.selectedClaimType = ""
                    }

                    if (openedTab.pk === "search") {
                        var result = action.payload?.data?.data?.filter((obj) => {
                            return obj.pk === "search";
                        });
                        state.claimSearchQuery = (result[0].query);
                        state.activeParentTab = "listClaims";
                    }
                    else if (openedTab.pk === "last10") {
                        state.activeParentTab = "last10";
                    }
                    else if (openedTab.pk === "add_new") {
                        state.activeParentTab = "editClaims";
                    }
                    else {
                        state.activeParentTab = "editClaims" + openedTab.pk;
                        state.selectedClaimType = "";
                    }

                }
                else {
                    state.claimParentTabs = [{ "pk": "last10", "is_opened": true }];
                }

                if (action?.payload?.data?.opened_tab_pks) {
                    state.openTabPks = action.payload.data.opened_tab_pks
                }

                if (currentState.activeParentTab === 'listClaims') {
                    const filteredClaims = action?.payload?.data?.data?.filter((obj) => {
                        return obj.pk === "search";
                    });
                    if (filteredClaims.length > 0) {
                        state.claimSearchQuery = filteredClaims[0].query;
                    }
                }

                if (action?.payload?.data?.code === 204 && action?.payload?.data?.message === "No Content") {
                    state.openTabPks = ["last10"];
                    state.claimParentTabs = [{ "pk": "last10", "is_opend": true }]
                    state.selectedClaimType = "";
                    state.activeParentTab = "last10"
                }
            })
        builder
            .addCase(getUserOpenedClaimModuleTabs.rejected, (state, action) => {
                state.dataLoading = false;
                state.apiError = (action?.payload?.status_code === 400 || action?.payload?.status_code === 204) ? "No Tab records in the server" : "An error occurred while fetching User Opened tab in Claims."
                if (action?.payload?.status_code === 400 || action?.payload?.status_code === 204) {
                    const response = service.AddRemoveSelectedTab({ "pk": "last10", "claim_id": "last10", "type": "claims", "action": "add", "practice_pk": getStorage('practice') });
                    if (response.data?.status == "success") {
                        state.claimParentTabs = [
                            {
                                "pk": "last10",
                                "is_opened": true
                            }
                        ]
                    }
                }
            })
        /*******************0 
         * 0**********/

        /*****************************/
        builder
            .addCase(addRemoveFromSelectedTab.pending, (state) => {
                state.dataLoading = true;
            })

        builder
            .addCase(addRemoveFromSelectedTab.fulfilled, (state, action) => {
                const { action: actionType, pk } = action.payload?.payload || {};
                const { tab } = action.payload;
                const { claimParentTabs, openTabPks, professionalClaim, billingInfo, serviceLineData, additionalClaimData, open_pks, pks } = current(state);
                if (actionType === 'add') {
                    // when user switch from a claim either to last10 or search then setting below states to initial state and 
                    //and to persist the data user changed details will be in corresponding i.e that are professionalClaim,billingInfo etc..
                    if (!["last10", "search"].includes(tab)) {
                        const initialStateKeys = {
                            claimPK: "",
                            diagnosis_pk: "",
                            billing_info_pk: "",
                            addition_info_pk: "",
                            patient_PK: "",
                            professionalClaimDetails: initialState.professionalClaimDetails,
                            billingInfoDetails: initialState.billingInfoDetails,
                            serviceLineDetails: initialState.serviceLineDetails,
                            diagnosisValidation: initialState.diagnosisValidation,
                            additionalClaimDetails: initialState.additionalClaimDetails,
                            ModifiersValidated: [],
                            inputM1Validated: [],
                            inputM2Validated: [],
                            inputM3Validated: [],
                            inputM4Validated: [],
                            checkForDuplicatediagnosis: false,
                            diagnosisesValidated: true,
                            CallInsurance: false,
                            insuranceCompleted: false,
                            serviceLocationPosList: [],
                            notifyMessage: "",
                            showNotification: false,
                            statusTag: "",
                            identifierData: [],
                            formWarningMessage: "",
                            formWarningData: [],
                            formWarningStatus: false
                        };
                        return {
                            ...state,
                            ...initialStateKeys,
                            dataLoading: false,
                            activeParentTab: tab
                        }
                    }
                    //when user switches to search and last10 back and forth just toggling the active parent tab
                    return {
                        ...state,
                        dataLoading: false,
                        activeParentTab: tab
                    };
                } else if (actionType === 'remove' && pk) {
                    //when  user closes a claim completely then resetting all corresponding states to initialState as well as remove 
                    //the particular claim from the backup arrays
                    const initialStateKeys = {
                        claimPK: "",
                        diagnosis_pk: "",
                        billing_info_pk: "",
                        addition_info_pk: "",
                        patient_PK: "",
                        professionalClaimDetails: initialState.professionalClaimDetails,
                        billingInfoDetails: initialState.billingInfoDetails,
                        serviceLineDetails: initialState.serviceLineDetails,
                        diagnosisValidation: initialState.diagnosisValidation,
                        additionalClaimDetails: initialState.additionalClaimDetails,
                        ModifiersValidated: [],
                        inputM1Validated: [],
                        inputM2Validated: [],
                        inputM3Validated: [],
                        inputM4Validated: [],
                        checkForDuplicatediagnosis: false,
                        diagnosisesValidated: true,
                        CallInsurance: false,
                        infoLoaded: false,
                        patientSelectedData: [],
                        patientAdvanceSearchData: [],
                        selectedPatient: "",
                        insuranceCompleted: false,
                        serviceLocationPosList: [],
                        notifyMessage: "",
                        showNotification: false,
                        statusTag: "",
                        identifierData: [],
                        alertMessage: "",
                        formWarningMessage: "",
                        formWarningData: [],
                        formWarningStatus: false,
                        activeSubTab: TAB1
                    };

                    let updatedClaimParentTabs = claimParentTabs?.filter(item => item.pk !== pk) || [];
                    let updatedOpenTabPks = openTabPks.filter(item => item !== pk) || [];
                    let updatedProfessionalClaim = professionalClaim.filter(item => item.claim_pk !== pk);
                    let updatedBillingInfo = billingInfo.filter(item => item.claim_pk !== pk);
                    let updatedServiceLineData = serviceLineData.filter(item => item.claim_pk !== pk);
                    let updatedAdditionalClaimData = additionalClaimData.filter(item => item.claim_pk !== pk);
                    let updatedOpen_pks = open_pks?.filter(item => item !== pk)
                    let updatedPks = pks.filter(item => item.claim_pk !== pk) || [];
                    /**
                     * checking current tab and closing tab is equal
                     * if activeParent tab is not changed getUseropenedClaim will not called ot will show blank
                     */
                    let switchTab = "";
                    if (state.activeParentTab === tab) {
                        switchTab = "last10"
                    }
                    else {
                        switchTab = tab;
                    }
                    return {
                        ...state,
                        dataLoading: false,
                        activeParentTab: switchTab,
                        claimParentTabs: updatedClaimParentTabs,
                        openTabPks: updatedOpenTabPks,
                        tabsOpen: Math.max(state.tabsOpen - 1, 0),
                        professionalClaim: updatedProfessionalClaim,
                        billingInfo: updatedBillingInfo,
                        serviceLineData: updatedServiceLineData,
                        additionalClaimData: updatedAdditionalClaimData,
                        open_pks: updatedOpen_pks,
                        pks: updatedPks,
                        ...initialStateKeys
                    };
                } else {
                    return {
                        ...state,
                        dataLoading: false,
                        activeParentTab: tab
                    };
                }
            });
        /************************************************************ */
        builder
            .addCase(addActiveSubTab.pending, (state) => {
                state.dataLoading = true;
            })

        builder
            .addCase(addActiveSubTab.fulfilled, (state, actions) => {
                state.activeSubTab = actions.payload.payload.active_tab;
                state.dataLoading = false;

            })

        builder
            .addCase(addActiveSubTab.rejected, (state, action) => {
                state.apiError = action.payload;
                state.dataLoading = false;
            })

        /*****************************/
        builder
            .addCase(getlast10TableData.pending, (state) => {
                state.dataLoading = true;
            })

        builder
            .addCase(getlast10TableData.fulfilled, (state, action) => {
                state.dataLoading = false
                state.last10Table.tableData = action.payload
            })

        builder
            .addCase(getlast10TableData.rejected, (state, action) => {
                state.dataLoading = false;
                state.apiError = action.payload;
            })
        /*****************************/

        /*****************************/
        builder
            .addCase(getSearchClaimsTableData.pending, (state) => {
                state.dataLoading = true;
            })

        builder
            .addCase(getSearchClaimsTableData.fulfilled, (state, action) => {
                state.dataLoading = false
                state.searchClaimsTable.tableData = action.payload?.data?.results ?? [];
                state.searchClaimsTable.totalPages = Math.ceil(action.payload?.data?.count / action.payload?.data?.page_size) ?? 0;
                state.searchClaimsTable.page = action.payload.isSearch ? 1 : action.payload?.data?.links?.current_page;
            })

        builder
            .addCase(getSearchClaimsTableData.rejected, (state, action) => {
                state.dataLoading = false;
                state.apiError = action.payload?.error_message ?? "An un-expected error occurred while fetching the data.";
            })
        /*****************************/

        /**
         * new claim all save details handled here
         */

        builder.addCase(getPatientDetails.pending, (state) => {
            state.dataLoading = true;
        })

        builder.addCase(getPatientDetails.fulfilled, (state, action) => {
            state.dataLoading = false;
            const {
                billingData,
                serviceData,
                additionalData,
                practiceData,
                accidentList,
                responsibilityType,
            } = action.payload;

            /**
             * billing info data is processed here
             */

            let {billingTabData,insuranceCompleted} = generateBillingTabSaveData(billingData,"");
            billingTabData = {
                ...billingTabData,
                claim_pk: "add_new",
            }

            state.insuranceCompleted=insuranceCompleted;

            /**
             * storing data to state for billing info
             */
            state.billingInfo.push(billingTabData);
            state.billingInfoDetails = billingTabData;

            /**state setting to true to call payer insurance */
            state.CallInsurance = true;

            /**serviceline data processed here */
            let claim_sub_status_list = [];
            let serviceLineTabData = generateServiceLineSaveData(serviceData);
            serviceLineTabData = {
                ...serviceLineTabData,
                responsibility_type: responsibilityType,
                claim_status: [],
                claim_sub_status: claim_sub_status_list,
                claim_pk: "add_new"
            }

            /**
             * modifier validation done here
             */
            let { modifiervalidate,
                modifier1,
                modifier2,
                modifier3,
                modifier4 } = validateModifier(serviceLineTabData?.procedures);

            state.ModifiersValidated = modifiervalidate;
            state.inputM1Validated = modifier1;
            state.inputM2Validated = modifier2;
            state.inputM3Validated = modifier3;
            state.inputM4Validated = modifier4;

            /**
             * storing serviceLine details to state
             */

            state.serviceLineData.push(serviceLineTabData);
            state.serviceLineDetails = serviceLineTabData;

            /**
             * additional claim data is processed here
             */

            let additionalTabData = generateAdditionalclaimSaveData(additionalData, practiceData, accidentList)
            additionalTabData = {
                ...additionalTabData,
                claim_pk: "add_new"
            }

            /**
            * storing additional claim details to state
            */

            state.additionalClaimData.push(additionalTabData);
            state.additionalClaimDetails = additionalTabData;

            /**pusing add_new to open_pks */
            state.open_pks.push("add_new");

        })

        builder.addCase(getPatientDetails.rejected, (state, action) => {
            state.notifyMessage = action.payload;
            state.showNotification = true;
            state.statusTag = "error";
            state.dataLoading = false;
        })

        //get patient additional details pending state
        builder.addCase(getEditDetails.pending, (state) => {
            state.editDetailsLoading = true;
        })
        //get patient additional data api success response handled here
        builder.addCase(getEditDetails.fulfilled, (state, actions) => {
            let { claimInfo,
                billingInfo,
                procedureInfo,
                additionalInfo,
                accidentList,
                responsibilityType,
                payload } = actions.payload
            state.claimPK = payload;
            state.diagnosis_pk = claimInfo?.pks?.diagnosises_pk;
            state.billing_info_pk = claimInfo?.pks?.billing_info_pk,
                state.addition_info_pk = claimInfo?.pks?.additional_claim_info_pk
            state.CallInsurance = true;
            state.patient_PK = claimInfo?.patient ?? ""
            /**pushing all pks */
            const pks = {
                ...claimInfo.pks,
                claim_pk: payload,

            }

            /**
             * check if pks contain same claim_pk if true remove existing and add new
             */
            const already_exist_pks = state.pks.some((item) => item.claim_pk == payload);
            if (!already_exist_pks) {
                state.pks.push(pks);
            }
            /**pushing add_new to open_pks */

            /**
             * check open_pks includes claim_pk if true remove it and add new
             */
            const already_exist_open_pks = state.open_pks.includes(payload);
            if (!already_exist_open_pks) {
                state.open_pks.push(payload);
            }

            /**
             * parent component claim info data processed here
             */
            let parentComponentData = generateClaimCommonUpdateData(claimInfo, payload)

            parentComponentData = {
                ...parentComponentData,
                claim_pk: payload
            }

            /** checking professional claim already conatins same data */
            const already_exist_professionalclaim = state.professionalClaim.some(
                (item) => item.claim_pk === payload
            );

            /**@already_exist_professionalclaim is true remove the same data and add new processed data */
            if (already_exist_professionalclaim) {
                state.professionalClaim = state.professionalClaim.filter(
                    (item) => item.claim_pk !== payload
                );
                state.professionalClaim.push(parentComponentData);
                state.professionalClaimDetails = parentComponentData;
            }
            else {
                state.professionalClaim.push(parentComponentData);
                state.professionalClaimDetails = parentComponentData;
            }

            let {billingInfoTabData,payerInfoData,insuranceCompleted} = generateBillingInfoUpdateData(billingInfo,payload);
            billingInfoTabData = {
                ...billingInfoTabData,
                ...payerInfoData,
                payerInsuranceList: billingInfoTabData.payer_info ? billingInfoTabData.payer_info : []
            }
            
            state.insuranceCompleted=insuranceCompleted;
            /**
             * patient payer info for existing data is handled here
             */

            billingInfo?.payer_info?.forEach(item => {
                if (item.priority === 1) {
                    billingInfoTabData = {
                        ...billingInfoTabData,
                        insurancePrimary: item.Id,
                        primarypolicyId: item.policy_id
                    }
                } else if (item.priority === 2) {
                    billingInfoTabData = {
                        ...billingInfoTabData,
                        insurancesecondary: item.Id,
                        secondarypolicyId: item.policy_id
                    }
                } else if (item.priority === 3) {
                    billingInfoTabData = {
                        ...billingInfoTabData,
                        insuranceTertiary: item.Id,
                        tertiarypolicyId: item.policy_id
                    }
                }
            })
            billingInfoTabData = {
                ...billingInfoTabData,
                claim_pk: payload,
                ...payerInfoData
            }

            /**
             * pushing billing tab data
             */

            /** checking billinng info claim already conatins same data */
            const already_exist_billinginfo = state.billingInfo.some(
                (item) => item.claim_pk === payload
            );

            /**@already_exist_billinginfo is true remove the same data and add new processed data */
            if (already_exist_billinginfo) {
                state.billingInfo = state.billingInfo.filter(
                    (item) => item.claim_pk !== payload
                );
                state.billingInfo.push(billingInfoTabData);
                state.billingInfoDetails = billingInfoTabData;
            }
            else {
                state.billingInfo.push(billingInfoTabData);
                state.billingInfoDetails = billingInfoTabData;
            }

            /**
             * serviceLine tab data processed here
             */
            let claim_sub_status_list = [];
            let serviceLineTabData = generateServiceLineUpdateData(procedureInfo);
            serviceLineTabData = {
                ...serviceLineTabData,
                responsibility_type: responsibilityType,
                claim_status:[],
                claim_sub_status: claim_sub_status_list,
                claim_pk: payload
            }

            /**
           * modifier validation done here
           */
            let { modifiervalidate,
                modifier1,
                modifier2,
                modifier3,
                modifier4 } = validateModifier(serviceLineTabData?.procedures);

            state.ModifiersValidated = modifiervalidate;
            state.inputM1Validated = modifier1;
            state.inputM2Validated = modifier2;
            state.inputM3Validated = modifier3;
            state.inputM4Validated = modifier4;

            /**
            * pushing serviceline tab data
            */

            /** checking service line info claim already conatins same data */
            const already_exist_serviceline = state.serviceLineData.some(
                (item) => item.claim_pk === payload
            );

            /**@already_exist_serviceline is true remove the same data and add new processed data */
            if (already_exist_serviceline) {
                state.serviceLineData = state.serviceLineData.filter(
                    (item) => item.claim_pk !== payload
                );
                state.serviceLineData.push(serviceLineTabData);
                state.serviceLineDetails = serviceLineTabData;
            }
            else {
                state.serviceLineData.push(serviceLineTabData);
                state.serviceLineDetails = serviceLineTabData;
            }

            /**
            * additional claim tab data processed here
            */


            let additionalTabData = generateAdditionaleUpdateData(additionalInfo, accidentList);

            additionalTabData = {
                ...additionalTabData,
                claim_pk: payload
            }

            /**
            * pushing additional claim tab data
            */

            /** checking service line info claim already conatins same data */
            const already_exist_additionalData = state.additionalClaimData.some(
                (item) => item.claim_pk === payload
            );

            /**@already_exist_additionalData is true remove the same data and add new processed data */
            if (already_exist_additionalData) {
                state.additionalClaimData = state.additionalClaimData.filter(
                    (item) => item.claim_pk !== payload
                );
                state.additionalClaimData.push(additionalTabData);
                state.additionalClaimDetails = additionalTabData;
            }
            else {
                state.additionalClaimData.push(additionalTabData);
                state.additionalClaimDetails = additionalTabData;
            }

            /**check for claim_alert */
            if(claimInfo.claim_alert){
                state.patientAlertModal = true;
                state.alertMessage = claimInfo.claim_alert ? claimInfo.claim_alert : "";
            }

            /**
             * setting form waring error on popup
             */
            let serverWarings = setWarningFromServer(claimInfo);
            state.formWarningData = serverWarings;
            state.editDetailsLoading = false;
        })
        //get patient additional details api rejected state
        builder.addCase(getEditDetails.rejected, (state, action) => {
            state.notifyMessage = action.payload;
            state.showNotification = true;
            state.statusTag = "error";
            state.editDetailsLoading = false;
        })

        //pos code list success case
        builder.addCase(GetServiceLocationPOSList.fulfilled, (state, actions) => {
            if (actions.payload.message == "pos_not_found") {
                state.serviceLocationPosList = [];
            }
            else {
                state.serviceLocationPosList = actions.payload;
            }
        })

        //pos code list success case
        builder.addCase(GetCPTCodeListWithDate.fulfilled, (state, actions) => {
            let { data, request } = actions.payload;
            let { serviceLineDetails, serviceLineData, professionalClaimDetails, professionalClaim } = current(state);
            let claim_pk = request.claimPK ? request.claimPK : "add_new";
            let newData = serviceLineDetails?.procedures[request?.index];
            if (request?.value) {
                if (request?.name == "dos_from") {
                    newData = { ...newData, [request?.name]: format(request?.value, 'yyyy-MM-dd') };
                    newData = { ...newData, ["dos_to"]: format(request?.value, 'yyyy-MM-dd') };
                }
                else {
                    newData = { ...newData, [request?.name]: format(request?.value, 'yyyy-MM-dd') };
                }
            }

            if (data.length > 0) {
                newData = { ...newData, ["fee_units"]: data[0]?.units };
                newData = { ...newData, ["fee"]: decimalConverter(data[0]?.fee) };
                newData = { ...newData, ["charges"]: decimalConverter(data[0]?.fee) * parseInt(data[0]?.units) };
            }

            state.serviceLineDetails.procedures[request?.index] = newData;

            /**
              * changing value of service line info array value
              */

            let index = serviceLineData.findIndex((item) => item.claim_pk === claim_pk);
            if (index >= 0) {
                state.serviceLineData[index] = state.serviceLineDetails;
            }


            let enteredDos = format(request?.value, 'yyyy-MM-dd')
            let serviceDateFrom = getServiceDateFrom(enteredDos, serviceLineDetails?.procedures, request?.index);
            let serviceDateTo = getServiceDateTo(enteredDos, serviceLineDetails?.procedures, request?.index);
            /**
             * storing service from date and to date to 
             */

            let updateValue = {
                ...professionalClaimDetails,
                ["service_from_date"]: serviceDateFrom,
                ["service_to_date"]: serviceDateTo
            }

            state.professionalClaimDetails = updateValue;

            /**
             * changing value of professional claim info array value
             */

            let index1 = professionalClaim.findIndex((item) => item.claim_pk === claim_pk);
            if (index1 >= 0) {
                state.professionalClaim[index1] = updateValue;
            }

        })

        /**
         * pending state of create or update a claim
         */
        builder.addCase(createAndUpdateClaim.pending, (state) => {
            state.dataLoading = true;
        })

        builder.addCase(createAndUpdateClaim.fulfilled, (state) => {
            state.dataLoading = false;
        })
        /**
         * rejected state of create or update claim
         */
        builder.addCase(createAndUpdateClaim.rejected, (state) => {
            state.dataLoading = false;
        })

        builder.addCase(getPatientHistory.pending, (state) => {
            state.dataLoading = true;
        })

        builder.addCase(getPatientHistory.fulfilled, (state, action) => {
            state.dataLoading = false;
            if (action?.payload?.icd_codes?.length > 0) {
                state.patientHistoryICD = action?.payload?.icd_codes;
            }

            if (action?.payload?.cpt_codes?.length > 0) {
                state.patientHistoryCPT = action?.payload?.cpt_codes
            }

            state.patientHistoryModal = true;
        })

        builder.addCase(getPatientHistory.rejected, (state) => {
            state.dataLoading = false;
        })

        builder.addCase(getChargePanelsList.pending, (state) => {
            state.dataLoading = true;
        })

        builder.addCase(getChargePanelsList.fulfilled, (state, action) => {
            state.dataLoading = false;
            if (Array.isArray(action?.payload?.data)) {
                state.chargePanelsList = action?.payload?.data;
                state.chargePanelsModal = true;
            } else {
                state.notifyMessage = "An error occurred while fetching Charge Panels."
                state.showNotification = true
                state.statusTag = "error";
                state.chargePanelsModal = false;
            }
        })

        builder.addCase(getChargePanelsList.rejected, (state) => {
            state.dataLoading = false;
            state.chargePanelsList = [];
            state.notifyMessage = "An error occurred while fetching Charge Panels.";
            state.showNotification = true;
            state.statusTag = "error";
            state.chargePanelsModal = false;
        })

        builder.addCase(addNewProcedureFromChargePanel.pending, (state) => {
            state.dataLoading = true;
        })

        builder.addCase(addNewProcedureFromChargePanel.fulfilled, (state, actions) => {
            // When invalid response is recieved;
            if (!actions?.payload?.data || !Array.isArray(actions?.payload?.data)) {
                state.notifyMessage = "An error occurred while fetching Panel Procedures."
                state.showNotification = true
                state.statusTag = "error";
                state.dataLoading = false;
                return;
            }

            // When valid response is recieved but the panel has not procedures;
            if (actions?.payload?.data?.length == 0) {
                state.notifyMessage = "There are no available procedures in the selected Charge Panel."
                state.showNotification = true
                state.statusTag = "error";
                state.dataLoading = false;
                return;
            }

            // Extract current state values using immer's current
            let { serviceLineDetails, serviceLineData, billingInfoDetails, professionalClaimDetails, claimSubStatus, claimPK } = current(state);
            let claim_pk = claimPK ? claimPK : "add_new";
            const paymentDone = serviceLineDetails?.procedures?.some(procedure => procedure.payment_done);

            // Helper functions for procedure data
            const getLastProcedureRowDos = (dos_type) => {
                const lastProcedure = serviceLineDetails?.procedures?.[0];
                const dos_from = lastProcedure?.dos_from;
                const dos_to = lastProcedure?.dos_to;

                if (dos_from && dos_to) {
                    return dos_type === 'dos_from' ? dos_from : dos_type === 'dos_to' ? dos_to : "";
                }
                return "";
            }

            const getDxPointers = () => {
                let dxPointers = "";
                const pointerMapping = {
                    "1": "A", "2": "B", "3": "C", "4": "D", "5": "E", "6": "F",
                    "7": "G", "8": "H", "9": "I", "10": "J", "11": "K", "12": "L"
                };

                for (let item = 1; item <= 12; item++) {
                    const diagnosisKey = `diagnosis${item}`;
                    if (serviceLineDetails?.[diagnosisKey]) {
                        dxPointers += pointerMapping[item] || "";
                    }
                }

                return dxPointers;
            }

            const getResponsibility = () => {
                if (!billingInfoDetails?.payerInsuranceList || billingInfoDetails?.payerInsuranceList.length === 0) {
                    return RESPONSIBILITY_TYPES.find(item => item.name === "Patient")?.id || "";
                }

                const sortedList = billingInfoDetails?.payerInsuranceList?.slice().sort((a, b) => a.priority - b.priority);
                return Number(sortedList[0]?.priority) || "";
            }

            const getClaimStatusAndSubStatus = () => ({
                claim_status: paymentDone ? null : professionalClaimDetails?.claim_status,
                claim_sub_status: paymentDone ? null : professionalClaimDetails?.claim_sub_status,
            });

            // Iterate through each procedure row received from the charge panel and 
            // transform the data to match the required claim procedure row specification
            const panelProcedures = actions.payload.data || [];
            const processedProcedures = [];
            for (let i = 0; i < panelProcedures.length; i++) {
                const panelProcedure = panelProcedures[i];

                // Create new procedure object with panel data
                const newProcedure = {
                    ...newProcedureRowItem,
                    id: `New-${crypto?.randomUUID()}`,
                    // Map panel procedure properties to procedure object
                    cpt_hcpcs: panelProcedure.cpt_hcpcs || '',
                    cpt_hcpcsList: panelProcedure.cpt_name ? [{
                        id: panelProcedure.cpt_hcpcs,
                        name: panelProcedure.cpt_name,
                        label: panelProcedure.cpt_name
                    }] : [],
                    pos_id: panelProcedure.pos || '',
                    pos_id_List: panelProcedure.pos_name ? [{
                        id: panelProcedure.pos,
                        name: panelProcedure.pos_name,
                        label: panelProcedure.pos_name
                    }] : [],
                    tos_id: panelProcedure.tos || '',
                    tos_id_List: panelProcedure.tos_name ? [{
                        id: panelProcedure.tos,
                        name: panelProcedure.tos_name,
                        label: panelProcedure.tos_name
                    }] : [],
                    proced_description: panelProcedure.proced_description || '',
                    fee_units: isNaN(panelProcedure.fee_units) ? "0" : String(panelProcedure.fee_units),
                    fee: isNaN(panelProcedure.fee) ? "0" : String(panelProcedure.fee),
                    charges: isNaN(panelProcedure.charges) ? "0" : String(panelProcedure.charges),
                    line_note: panelProcedure.line_note || '',
                    drug_code: panelProcedure.drug_code || '',
                    drug_unit_type: panelProcedure.drug_unit_type || '',
                    drug_unit_value: panelProcedure.drug_unit_value || '',
                    measurement_unit_type: panelProcedure.measurement_unit_type || '',
                    measurement_unit_value: panelProcedure.measurement_unit_value || '',
                    anesthesia: panelProcedure.anesthesia || '',
                    m1_id: panelProcedure.m1_id || '',
                    m2_id: panelProcedure.m2_id || '',
                    m3_id: panelProcedure.m3_id || '',
                    m4_id: panelProcedure.m4_id || '',
                    m1List: panelProcedure.m1_name ? [{ id: panelProcedure.m1_id, name: panelProcedure.m1_name }] : [],
                    m2List: panelProcedure.m2_name ? [{ id: panelProcedure.m2_id, name: panelProcedure.m2_name }] : [],
                    m3List: panelProcedure.m3_name ? [{ id: panelProcedure.m3_id, name: panelProcedure.m3_name }] : [],
                    m4List: panelProcedure.m4_name ? [{ id: panelProcedure.m4_id, name: panelProcedure.m4_name }] : [],
                    cpt_epsdt: panelProcedure.cpt_epsdt,
                    emg: panelProcedure.emg,
                    // Add common fields for all procedures
                    dos_from: getLastProcedureRowDos('dos_from'),
                    dos_to: getLastProcedureRowDos('dos_to'),
                    dx_pointers: getDxPointers(),
                    responsibility_type: getResponsibility(),
                    ...getClaimStatusAndSubStatus()
                };

                processedProcedures.push(newProcedure)

                // Update claim sub status if applicable
                if (claimSubStatus.length > 0 && professionalClaimDetails.claim_status) {
                    let filterData = claimSubStatus?.filter(item =>
                        item.parent_claim_status_ids?.includes(professionalClaimDetails.claim_status)
                    );
                    state.serviceLineDetails.claim_sub_status[serviceLineDetails.procedures.length] = filterData;
                }
            }

            // When there's only single row currently in the service line and if it does not have a cpt slected then
            // replace procedure row completely with the panel data.
            // otherwise, spread the new panel procedure with the existing procedure rows.
            if (state.serviceLineDetails.procedures.length == 1 && !state.serviceLineDetails.procedures[0]?.cpt_hcpcs) {
                state.serviceLineDetails.procedures = processedProcedures;
            } else {
                state.serviceLineDetails.procedures = [...state.serviceLineDetails.procedures, ...processedProcedures];
            }

            // Validate modifiers after adding all procedures
            let { modifiervalidate, modifier1, modifier2, modifier3, modifier4 } =
                validateModifier(state.serviceLineDetails.procedures);

            state.ModifiersValidated = modifiervalidate;
            state.inputM1Validated = modifier1;
            state.inputM2Validated = modifier2;
            state.inputM3Validated = modifier3;
            state.inputM4Validated = modifier4;

            // Update the service line data array with the updated service line details
            let index = serviceLineData.findIndex((item) => item.claim_pk === claim_pk);
            if (index >= 0) {
                state.serviceLineData[index] = state.serviceLineDetails;
            }

            // Reset panel-related states
            state.chargePanelsModal = false;
            state.chargePanelsList = [];

            // Automatically scroll to the last procedure row after a brief delay
            // to ensure DOM updates are complete, using smooth scrolling behavior
            setTimeout(() => {
                document.getElementById(`procedure_row_${serviceLineDetails?.procedures[serviceLineDetails?.procedures.length - 1]?.id}`)?.scrollIntoView({
                    behavior: 'smooth'
                });
            }, 200);

            // Set loading state to false as we have data
            state.dataLoading = false;
        })

        builder.addCase(addNewProcedureFromChargePanel.rejected, (state) => {
            state.dataLoading = false;
            state.notifyMessage = "An error occurred while fetching Panel Procedures."
            state.showNotification = true
            state.statusTag = "error";
        })

        builder.addCase(ActivateInactiveClaim.pending, (state) => {
            state.dataLoading = true;
        })
        builder.addCase(ActivateInactiveClaim.fulfilled, (state, action) => {
            state.dataLoading = false;
            state.notifyMessage = "Claim activated successfully."
            state.showNotification = true
            state.statusTag = "success"
            const claimPk = action.payload.claimPK;

            let { professionalClaimDetails, professionalClaim } = current(state);
            let updateValue = {
                ...professionalClaimDetails,
                active: true,
                claim_inactive: false
            }
            /**
             * updating profession claim details immediately
             */
            state.professionalClaimDetails = updateValue;
            /**
             * changing value of professional claim array value
             */
            let index = professionalClaim.findIndex((item) => item.claim_pk === claimPk);
            if (index >= 0) {
                state.professionalClaim[index] = updateValue;
            }
        })
        builder.addCase(ActivateInactiveClaim.rejected, (state) => {
            state.dataLoading = false;
            state.notifyMessage = "An error occurred while activating the claim."
            state.showNotification = true
            state.statusTag = "error"
        }),
            /**setting identifier */
            builder.addCase(fetchIdentifiers.fulfilled, (state, actions) => {
                let { professionalClaim, professionalClaimDetails } = current(state);
                let { data, claimPK } = actions.payload;
                let claim_pk = claimPK ? claimPK : "add_new";
                let updateValue = {
                    ...professionalClaimDetails,
                    identifierData: data
                }

                state.professionalClaimDetails = updateValue;
                let index = professionalClaim.findIndex((item) => item.claim_pk === claim_pk);
                if (index >= 0) {
                    state.professionalClaim[index] = updateValue;
                }

            })
    },
});




export const {
    resetToInitial,
    setOpenTab,
    setLast10Sort,
    setSearchClaimsSort,
    resetSearchClaimData,
    setSearchClaimFilterData,
    onPatientDropDownSelectAction,
    updateParentComponentData,
    updateBillingInfoData,
    updatePayerInfoDetails,
    updateAdditionalInfoData,
    updateProcedureCPT,
    updatePOSList,
    updateTOSList,
    updateModifierList,
    updateProcedureOtherDetails,
    ICDTypeChange,
    HandleICDChange,
    resetNotifyWindow,
    showNotifications,
    addNewProcedureRow,
    removeProcedureRowItem,
    showModalWindow,
    setFormWarningMessages,
    setFormWaringData,
    setActiveSubTab,
    removeClaimDetails,
    getClaimDetailsByClaimPK,
    saveClaimIDWaringPopUP,
    addDiganosisVariablePatientHistory,
    removeDiganosisVariablePatientHistory,
    addCPTFrompatientHistoryTable,
    handleClaimPageUnlock,
    filterClaimSubStatus
} = claimManagementSlice1.actions;

export default claimManagementSlice1.reducer;