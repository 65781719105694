// React core imports
import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Common components
import BackArrowWithLabel from '../../commons/Back';
import Table from '../../commons/Table/Table';
import Pagination from '../../commons/pagination';
import Notify from '../../commons/notify';
import SelectInput from '../../commons/input/SelectInput';
import NoPermission from '../../NoPermissionPage/NoPermission';
import CommonButton from '../../commons/Buttons';

// Custom components
import { ChargePanelMasterTable } from './ChargePanelTables';
import PanelCreateUpdateModal from './Components/PanelCreateUpdateModal';

// Utilities and services
import i18n from '../../../utilities/i18n';
import { ROUTE_CODE_MANAGEMENT, PAGING_END_INDEX, DEFAULT_PAGING_SIZE, ACTIVE_TYPES, ROUTE_PRACTICE_CHARGE_PANELS_EDIT } from '../../../utilities/staticConfigs';
import { getStorage } from '../../../utilities/browserStorage';
import { checkPermission, commonTableBody } from '../../../utilities/commonUtilities';
import service from './service';
import { permission_key_values_accountsetup, super_admin_privileges, customer_admin_privilege } from '../../../utilities/permissions';

// Context
import LoadingContext from '../../../container/loadingContext';


/**
 * Charge Panels Component
 * renders on the path /practice/charge-panels
 * @returns {React.ReactElement}
 */
function ChargePanels() {
    // Validate if User is in the Admin Module
    // Since this component is meant for Practice module only
    // Need to show No Permission page if User is in the Admin Module
    const isAdminModule = localStorage.getItem('isAdminModule') === 'true';

    const [permission] = useState(() => {
        const viewPermission = checkPermission(
            permission_key_values_accountsetup.account_setup_code_management_view,
            super_admin_privileges.super_admin_full_privilege,
            customer_admin_privilege
        )

        const addOrModifyPermission = checkPermission(
            permission_key_values_accountsetup.account_setup_code_management_add,
            permission_key_values_accountsetup.account_setup_code_management_modify,
            super_admin_privileges.super_admin_full_privilege,
            customer_admin_privilege
        )

        return { viewPermission, addOrModifyPermission };
    });

    if (isAdminModule || !permission.viewPermission) {
        return <NoPermission />
    }

    // Routing
    const history = useHistory();

    // Notification states
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');

    // Loading context
    const setShowLoadingBar = useContext(LoadingContext);

    // Table states
    const [tableUpdateFlag, setTableUpdateFlag] = useState(0);
    const activeStatusInitialValue = ACTIVE_TYPES.find(({ name }) => name === "Active")?.id;
    const [status, setStatus] = useState(activeStatusInitialValue);

    // Pagination states
    const [activePage, setActivePage] = useState(1);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);
    const [startIndex, setStartIndex] = useState(0);
    const [totalPage, setTotalPage] = useState(1);

    // Charge panels Table data state
    const [chargePanels, setChargePanels] = useState([]);

    // Only keep modal visibility state in parent
    const [showModal, setShowModal] = useState(false);

    // Show notification with a message
    const showNotifyWindow = (action, type, desc, age = 3000) => {
        if (action === 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    };

    // Navigate back to the codes management page
    const navigateBackToCodes = () => {
        history.push(ROUTE_CODE_MANAGEMENT);
    };

    // Fetch charge panels with pagination and status filter
    const fetchChargePanels = async (page = 1, pageSize = DEFAULT_PAGING_SIZE, status = activeStatusInitialValue) => {
        try {
            setShowLoadingBar(true);
            const response = await service.ListChargePanels({
                practice_pk: getStorage("practice"),
                page,
                page_size: pageSize,
                list_type: "list",
                status,
            });

            if (response && response.data?.results?.length > 0) {
                setTotalPage(Math.ceil(response.data?.count / pageSize));
                setChargePanels(response.data?.results);

                const rowArray = commonTableBody(response.data?.results, ChargePanelMasterTable.tableBodyData[0]);
                ChargePanelMasterTable.tableBodyData = rowArray;
                setTableUpdateFlag(prevFlag => prevFlag + 1);
            } else if (response && response.data?.count === 0) {
                setChargePanels([]);
                setTotalPage(1);
                setActivePage(1);
                setStartIndex(0);
                setEndIndex(PAGING_END_INDEX);
                const rowArray = commonTableBody(response.data?.results, ChargePanelMasterTable.tableBodyData[0]);
                ChargePanelMasterTable.tableBodyData = rowArray;
                setTableUpdateFlag(prevFlag => prevFlag + 1);
            } else {
                handleGetTableDataError(new Error("No data found"));
            }
        } catch (error) {
            handleGetTableDataError(error);
        } finally {
            setShowLoadingBar(false);
        }
    };

    // Handle errors when fetching table data
    const handleGetTableDataError = (error) => {
        console.error(error);
        showNotifyWindow("show", "error", error.message);
        resetTableData();
    };

    // Reset table data to initial state
    const resetTableData = () => {
        setChargePanels([]);
        setTotalPage(1);
        setActivePage(1);
        setStartIndex(0);
        setEndIndex(PAGING_END_INDEX);

        const rowArray = commonTableBody([], ChargePanelMasterTable.tableBodyData[0]);
        ChargePanelMasterTable.tableBodyData = rowArray;
        setTableUpdateFlag(prevFlag => prevFlag + 1);
    };

    // Handle status filter change
    const handleStatusChange = (e) => {
        setStatus(e.target.value);
        fetchChargePanels(1, DEFAULT_PAGING_SIZE, e.target.value);
        resetPagination();
    };

    // Reset pagination to initial state
    const resetPagination = () => {
        setActivePage(1);
        setStartIndex(0);
        setEndIndex(PAGING_END_INDEX);
    };

    // Handle dropdown actions for the charge panels table
    const handleDropdownAction = (id, name) => {
        if (!permission.addOrModifyPermission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }

        const panelId = chargePanels.find((panel) => panel.id === id)?.id;

        if (name.toLowerCase() === "edit" && panelId) {
            history.push({
                pathname: ROUTE_PRACTICE_CHARGE_PANELS_EDIT,
                state: { id: panelId }
            });
        }
    };

    // Handle successful addition of a new charge panel
    const handleAddNewSuccess = (newPanel) => {
        fetchChargePanels(1, DEFAULT_PAGING_SIZE, activeStatusInitialValue);
        if (newPanel.id) {
            history.push({
                pathname: ROUTE_PRACTICE_CHARGE_PANELS_EDIT,
                state: { id: newPanel.id }
            });
        } else {
            resetPagination();
        }
    };

    // Pagination handlers
    const onPagePrevious = () => {
        const previousPage = Math.max(startIndex + 1 - PAGING_END_INDEX, 1);
        setActivePage(previousPage);
        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        fetchChargePanels(previousPage, DEFAULT_PAGING_SIZE, status);
    };

    const onPageUp = (e) => {
        const page = Number(e.target.id);
        setActivePage(page);
        fetchChargePanels(page, DEFAULT_PAGING_SIZE, status);
    };

    const onPageNext = () => {
        const nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setEndIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        fetchChargePanels(nextPage, DEFAULT_PAGING_SIZE, status);
    };

    // Handler for opening the create modal
    const handleOpenCreateModal = () => {
        setShowModal(true);
    };

    // Handler for closing the modal
    const handleCloseModal = () => {
        setShowModal(false);
    };

    // Initial data fetch
    useEffect(() => {
        fetchChargePanels();
    }, []);

    return (
        <>
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />

            <div className="col-md-8 mt-3">
                <div className="box box-content-white">
                    <div style={{ padding: "5px" }} />
                    <div className="box-head pl-0 pr-0">
                        <div className="dataTables_filter">
                            <div className="link dictionaries-back" onClick={navigateBackToCodes}>
                                <BackArrowWithLabel label={i18n.t("codes.codes")} />
                            </div>
                        </div>
                    </div>

                    <div className="box-content">
                        <div className="alignRight margin-bottom8">
                            <CommonButton
                                variant="contained"
                                onClick={handleOpenCreateModal}
                                label={i18n.t("buttons.addNew")}
                            />
                        </div>

                        <PanelCreateUpdateModal
                            action="create"
                            setShowLoadingBar={setShowLoadingBar}
                            showNotifyWindow={showNotifyWindow}
                            handleSuccess={handleAddNewSuccess}
                            showModal={showModal}
                            onClose={handleCloseModal}
                        />

                        <div className="alignRight margin-bottom8 mr-2">
                            <SelectInput
                                id="status"
                                name="status"
                                data={ACTIVE_TYPES}
                                value={status}
                                onValueChange={handleStatusChange}
                                selectOptionRemove={true}
                            />
                        </div>
                    </div>
                        <div className="table-responsive">
                            <Table
                                tableObject={ChargePanelMasterTable}
                                dropDownFunction={handleDropdownAction}
                                key={tableUpdateFlag}
                            />
                        </div>
                        <div className="padding-top10"></div>
                        <Pagination
                            totalPage={totalPage}
                            activePage={activePage}
                            startIndex={startIndex}
                            endIndex={endIndex}
                            onPagePrevious={onPagePrevious}
                            onPageUp={onPageUp}
                            onPageNext={onPageNext}
                        />
                </div>
            </div>
        </>
    );
}

export default ChargePanels;
